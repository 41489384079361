import React from "react"

const ThaiTermsAndCons = () => {
  return (
    <div style={{ maxWidth: "100%" }}>
      <h2>
        ข้อตกลงและเงื่อนไขการใช้บริการเว็บไซต์ของ BMW และแอปพลิเคชันของ BMW
      </h2>
      <p>
        BMW Group Thailand
        <br />
        ฉบับ ณ วันที่ 18 พฤษภาคม 2565
      </p>
      <div>
        <p>&nbsp;</p>
        <p>
          ข้อตกลงและเงื่อนไขการใช้บริการเว็บไซต์ของ BMW และแอปพลิเคชันของ BMW
          นี้ (“<b>ข้อตกลงการใช้บริการ</b>”)
          ถือเป็นสัญญาของเราและผู้ใช้แพลตฟอร์มหรือลูกค้าของเรา (“<b>ท่าน</b>”
          และ “<b>ของท่าน</b>”) สำหรับการใช้บริการแพลตฟอร์มหรือบริการของเรา
        </p>
        <p>&nbsp;</p>
        <p>
          <b>1.&nbsp;ข้อมูลเกี่ยวกับเรา แพลตฟอร์มและบริการของเรา</b>
        </p>
        <p>
          เว็บไซต์ของ BMW แอปพลิเคชันของ BMW
          และ/หรือช่องทางออนไลน์หรืออิเล็กทรอนิกส์อื่น ๆ ของ BMW (เรียกรวมกันว่า
          “<b>แพลตฟอร์ม</b>”) ดำเนินการโดยบริษัท บีเอ็มดับเบิลยู ลิสซิ่ง
          (ประเทศไทย) จำกัด และบริษัท บีเอ็มดับเบิลยู (ประเทศไทย) จำกัด (“
          <b>กลุ่ม BMW (ประเทศไทย)</b>” “<b>เรา</b>” “<b>พวกเรา</b>” และ “
          <b>ของเรา</b>”)
          เราจดทะเบียนในประเทศไทยและตั้งสำนักงานจดทะเบียนของเราอยู่ เลขที่ 87/2
          ชั้น 44, 50 และ 51 อาคารซีอาร์ซีทาวเวอร์ ออลซีซั่นส์เพลส ถนนวิทยุ
          แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330
        </p>
        <p>
          แพลตฟอร์มของเรา ได้แก่&nbsp;
          <a href="http://www.bmw.co.th/">www.bmw.co.th</a>,&nbsp;
          <a href="http://www.yourbmwleasingthailand.com/" target="_blank">
            www.yourbmwleasingthailand.com
          </a>
          ,&nbsp;
          <a href="http://www.mini.co.th/" target="_blank">
            www.mini.co.th
          </a>
          ,&nbsp;
          <a href="http://www.bmw-motorrad.co.th/" target="_blank">
            http://www.bmw-motorrad.co.th
          </a>
          &nbsp;(“<b>เว็บไซต์ของ BMW</b>”) และ/หรือแอปพลิชัน My BMW (“
          <b>แอปพลิเคชันของ BMW</b>”) ส่งมอบคุณลักษณะ ข่าวสาร เนื้อหา ข้อมูล
          เครื่องมือสื่อสาร พอร์ทัลออนไลน์สำหรับการบริการตนเอง สิทธิพิเศษ
          บริการเสริมและบริการทางการเงินที่เกี่ยวข้องกับการส่งมอบยานพาหนะและบริการที่เกี่ยวข้องอื่นๆ
          ของเรา (รวมหรือแยกจากกันเรียกว่า “<b>บริการ</b>”)
          ซึ่งรวมถึงแต่ไม่จำกัดเพียง
        </p>
        <ul>
          <li>การลงทะเบียนบัญชี</li>
          <li>การนัดหมายสำหรับบริการทดลองขับ (Test Drive)</li>
          <li>การปรับแต่งคุณลักษณะของตัวรถ</li>
          <li>ฟังก์ชั่นตามความต้องการ</li>
          <li>บริการ ConnectedDrive</li>
          <li>การลงทะเบียนจองเข้าร่วมงานอีเว้นท์</li>
          <li>การขายออนไลน์และร้านค้าดิจิทัล</li>
          <li>การจัดการสถานะของยานพาหนะ</li>
          <li>ระบบบริการจากระยะไกล</li>
          <li>เครื่องมือประกอบยานพาหนะ BMW (BMW Configurator)</li>
          <li>เครื่องมือประกอบยานพาหนะ MINI (MINI Configurator)</li>
        </ul>
        <p>
          ในการเข้าถึงหรือใช้แพลตฟอร์มของเรา
          ข้อตกลงการใช้บริการนี้จะมีผลใช้บังคับกับท่านโดยอัตโนมัติและท่านตกลงที่จะผูกพันกับข้อตกลงการใช้บริการนี้
          ตลอดระยะเวลาที่ท่านใช้งานแพลตฟอร์มและบริการของเรา
          โปรดอ่านข้อตกลงการใช้บริการอย่างถี่ถ้วนก่อนที่ท่านจะเริ่มใช้งานแพลตฟอร์มและบริการของเรา
          เนื่องจากข้อตกลงการใช้บริการนี้จะใช้บังคับกับการที่ท่านใช้แพลตฟอร์มและบริการของเราต่อไป
          หากท่านไม่ยอมรับข้อตกลงการใช้บริการนี้
          โปรดหยุดใช้แพลตฟอร์มและบริการของเรา
        </p>
        <p>&nbsp;</p>
        <p>
          <b>2.&nbsp;ข้อกำหนดอื่นที่เกี่ยวข้อง</b>
        </p>
        <p>
          ข้อตกลงการใช้บริการนี้อ้างถึงข้อกำหนดเพิ่มเติมซึ่งมีผลใช้บังคับกับการใช้แพลตฟอร์มและบริการของเราด้วย
          ดังต่อไปนี้
        </p>
        <ul>
          <li>
            ข้อความปฏิเสธความรับผิดชอบตามกฎหมายของเรา -&nbsp;
            <a href="https://www.bmw.co.th/th/footer/metanavigation/legal-disclaimer-pool/legal-disclaimer.html">
              <b>คลิกที่นี่เพื่ออ่าน</b>
            </a>
            <b> </b>
            กำหนดรายละเอียดทางกฎหมายของเราและข้อกำหนดของแพลตฟอร์มของเราเพิ่มเติม
            อนึ่ง
            ในกรณีที่มีข้อขัดแย้งระหว่างข้อตกลงการใช้บริการนี้และข้อความปฏิเสธความรับผิดชอบตามกฎหมาย
            ให้ถือเอาข้อตกลงการใช้บริการนี้มีความสำคัญเหนือกว่า
            ในส่วนที่เกี่ยวข้องกับแพลตฟอร์มของเรา
          </li>
          <li>
            นโยบายความเป็นส่วนตัวของเรา -&nbsp;
            <a href="https://www.bmw.co.th/th/footer/legal-information/privacy-policy.html">
              <b>คลิกที่นี่เพื่ออ่าน</b>
            </a>
            <b> </b>
            กำหนดข้อกำหนดในการใช้หรือเปิดเผยข้อมูลส่วนบุคคลที่เราเก็บรวบรวมจากท่าน
            หรือข้อมูลที่ท่านมอบให้เรา โปรดดูส่วนข้างล่างในเรื่อง '
            <i>ข้อมูลส่วนบุคคล</i>' ด้วย
          </li>
          <li>
            นโยบายการใช้คุกกี้ของเรา -&nbsp;
            <a href="https://www.bmw.co.th/en/footer/footer-section/cookie-policy.html">
              <b>คลิกที่นี่เพื่ออ่าน</b>
            </a>
            <b> </b>กำหนดรายละเอียดเกี่ยวกับคุกกี้บนแพลตฟอร์มของเรา
          </li>
          <li>
            ข้อตกลงและเงื่อนไขการใช้เครื่องมือประกอบยานพาหนะ BMW (BMW
            Configurator) ของเรา -&nbsp;
            <a href="https://www.bmw.co.th/th/index.html">
              <b>คลิกที่นี่เพื่ออ่าน</b>
            </a>
            <b> </b>กำหนดข้อตกลงและเงื่อนไขสำหรับการใช้ "BMW Configurator"
            บนแพลตฟอร์มของเรา
          </li>
          <li>
            ข้อตกลงและเงื่อนไขการใช้เครื่องมือประกอบยานพาหนะ MINI (MINI
            Configurator)&nbsp;ของเรา -&nbsp;
            <a href="https://configure.mini.co.th/en_TH/model-ranges">
              <b>คลิกที่นี่เพื่ออ่าน</b>
            </a>
            <b> </b>กำหนดข้อตกลงและเงื่อนไขสำหรับการใช้ "MINI
            Configurator&nbsp;" บนแพลตฟอร์มของเรา
          </li>
        </ul>
        <p>
          หากมีข้อกำหนดและเงื่อนไข
          แนวปฏิบัติและ/หรือนโยบายเพิ่มเติมอื่นใดซึ่งได้ประกาศใช้ตามแพลตฟอร์มหรือบริการของเรา
          ท่านตกลงที่จะอ่านและปฏิบัติตามข้อกำหนดและเงื่อนไข
          แนวปฏิบัติและ/หรือนโยบายดังกล่าวร่วมกับข้อตกลงและเงื่อนไขการใช้บริการนี้อย่างถี่ถ้วน
          ในขณะที่ใช้แพลตฟอร์มและบริการของเรา
        </p>
        <p>&nbsp;</p>
        <p>
          <b>3.&nbsp;การเปลี่ยนแปลงข้อตกลงการใช้บริการนี้</b>
        </p>
        <p>
          เราสามารถเปลี่ยนแปลงส่วนใดส่วนหนึ่งของข้อตกลงการใช้บริการนี้ กฎเกณฑ์
          นโยบาย
          และ/หรือสิทธิประโยชน์ที่เกี่ยวข้องกับบริการของเราได้ตลอดเวลาโดยการแก้ไขหน้านี้
          โดยเราจะดำเนินการอย่างเหมาะสมเมื่อทำเปลี่ยนแปลง ข้อตกลงการใช้บริการนี้
          กฎเกฑณ์
          นโยบายและ/หรือสิทธิประโยชน์ที่เกี่ยวข้องกับบริการของเราจะมีการเปลี่ยนแปลงด้วยเหตุผลดังต่อไปนี้เท่านั้น
        </p>
        <ul>
          <li>
            เราเชื่อโดยมีเหตุผลอันสมควรว่าการเปลี่ยนแปลงจะทำให้ข้อตกลงการใช้บริการนี้เข้าใจได้ง่ายขึ้นหรือเป็นธรรมกับท่านยิ่งขึ้น
          </li>
          <li>
            เราจำเป็นต้องทำการเปลี่ยนแปลงเนื่องจากมีการเปลี่ยนแปลงกฎหมาย
            การตัดสินใจของผู้ตรวจการแผ่นดิน
            หรือเนื่องจากข้อกำหนดด้านกฎระเบียบอื่น
          </li>
          <li>
            เราทำการเปลี่ยนแปลงเนื่องจากมีการเปลี่ยนแปลงแนวทางปฏิบัติของอุตสาหกรรม
            ข้อตกลง เทคโนโลยี หรือระบบที่เราใช้ในการดำเนินธุรกิจ
            หรือเพื่อให้สอดคล้องกับแนวปฏิบัติที่ดี ทั้งนี้
            เราจะทำการเปลี่ยนแปลงเฉพาะเมื่อเป็นประโยชน์แก่ท่านหรือเพื่อเป็นประโยชน์แก่ท่านมากยิ่งขึ้น
          </li>
          <li>
            เพื่อนำเสนอการเปลี่ยนแปลงสู่แพลตฟอร์มของเรา หรือนำเสนอบริการใหม่
          </li>
        </ul>
        <p>
          โปรดตรวจดูหน้านี้เป็นครั้งคราวเพื่อที่ท่านจะได้ทราบถึงการเปลี่ยนแปลงที่เราได้ทำขึ้น
          ซึ่งหากท่านใช้บริการของเราต่อไป ให้ถือว่าท่านยอมรับการเปลี่ยนแปลง
          และการเปลี่ยนแปลงดังกล่าวจะมีผลผูกพันกับท่าน อย่างไรก็ดี
          ท่านสามารถหยุดใช้แพลตฟอร์มของเราได้ตลอดเวลา
          หากท่านไม่ต้องการยอมรับการเปลี่ยนแปลงที่เราได้ทำกับข้อตกลงการใช้บริการนี้
        </p>
        <p>&nbsp;</p>
        <p>
          <b>4.&nbsp;การเปลี่ยนแปลงแพลตฟอร์มของเรา</b>
        </p>
        <p>
          เราสงวนสิทธิ์ในการแก้ไขปรับปรุงแพลตฟอร์มของเราเป็นครั้งคราว
          และอาจเปลี่ยนแปลงบริการ ฟังก์ชันการทำงาน และเนื้อหาได้ตลอดเวลา
          อย่างไรก็ตาม
          ขอเรียนว่าเนื้อหาบนแพลตฟอร์มของเราอาจไม่ใช่ข้อมูลล่าสุดและเราไม่มีหน้าที่ในการปรับปรุงเนื้อหานั้นให้เป็นปัจจุบัน
        </p>
        <p>
          เราไม่ยืนยันว่าแพลตฟอร์ม บริการ ฟังก์ชันการทำงาน หรือเนื้อหาใดๆ
          ที่อยู่บนแพลตฟอร์มของเราจะปราศจากข้อผิดพลาดหรือการตกหล่น
        </p>
        <p>&nbsp;</p>
        <p>
          <b>5.&nbsp;การเข้าถึงแพลตฟอร์มของเรา &nbsp;</b>
        </p>
        <p>แพลตฟอร์มของเราได้จัดให้ท่านใช้งานโดยท่านไม่ต้องเสียค่าใช้จ่าย</p>
        <p>
          ทั้งนี้ เราไม่ยืนยันว่าแพลตฟอร์ม บริการ หรือเนื้อหาใดๆ
          บนแพลตฟอร์มของเราจะพร้อมใช้งานตลอดเวลาหรือไม่มีการหยุดชะงัก
          เราอาจระงับ เพิกถอน ยุติหรือเปลี่ยนแปลงแพลตฟอร์มและบริการของเรา
          ทั้งหมดหรือเพียงบางส่วน โดยไม่แจ้งให้ท่านทราบล่วงหน้า
          เราจะไม่รับผิดต่อท่านแต่อย่างใดหากแพลตฟอร์มและ/หรือบริการของเราไม่สามารถใช้งานได้ในคราวใด
          หรือช่วงเวลาใด ไม่ว่าจะด้วยเหตุผลใดก็ตาม &nbsp;
        </p>
        <p>
          ในการเข้าถึงแพลตฟอร์มของเรา
          ท่านรับประกันและรับรองว่าท่านมีอายุอย่างน้อย 20 (ยี่สิบ) ปี
          หรือมีความสามารถทางกฎหมายในการเข้าทำนิติกรรม
        </p>
        <p>
          ท่านจะเป็นผู้รับผิดชอบในการเตรียมการทั้งปวงที่จำเป็นเพื่อเข้าถึงแพลตฟอร์มของเรา
          และมีหน้าที่รับผิดชอบในการกำกับดูแลว่าทุกคนที่เข้าถึงแพลตฟอร์มของเราผ่านการเชื่อมต่ออินเทอร์เน็ตหรือบริการอิเล็กทรอนิกส์ของท่านทราบถึง
          และปฏิบัติตามข้อตกลงการใช้บริการนี้ และข้อกำหนดและเงื่อนไขอื่นๆ
          ที่เกี่ยวข้อง
        </p>
        <p>&nbsp;</p>
        <p>
          <b>6.&nbsp;บัญชีและรหัสผ่านของท่าน</b>
        </p>
        <p>
          ท่านตกลงที่จะลงทะเบียนบัญชีของท่านบนแพลตฟอร์มของเราในการเข้าถึงบริการบางประเภท
          โดยท่านต้องเก็บข้อมูลทั้งหมดที่เกี่ยวข้องกับบัญชีของท่านในการเข้าถึงแพลตฟอร์มของเราเป็นความลับ
          และใช้มาตรการป้องกันที่เหมาะสมเพื่อป้องกันไม่ให้ผู้อื่นรู้ข้อมูลดังกล่าว
        </p>
        <p>
          ท่านสามารถขอเปลี่ยนแปลงข้อมูลประจำตัวของบัญชีของท่านได้ เช่น
          ชื่อผู้ใช้ รหัสผ่าน และ/หรือ PIN
          (หมายเลขประจำตัวบุคคล)ภายใต้ข้อตกลงและเงื่อนไขของเรา
          ท่านยังคงผูกพันต่อการกระทำใด ๆ
          ที่ท่านได้กระทำก่อนการเปลี่ยนแปลงดังกล่าว
        </p>
        <p>
          ท่านจะต้องทำตามขั้นตอนที่เหมาะสมเพื่อรักษาความปลอดภัยให้อุปกรณ์ของท่าน
          เช่น
        </p>
        <ul>
          <li>ตรวจสอบว่าอุปกรณ์ของท่านล็อกไว้เมื่อไม่ได้ใช้งาน</li>
          <li>ดูแลอุปกรณ์ของท่านให้ปราศจากไวรัส มัลแวร์ หรือสปายแวร์</li>
          <li>
            ออกจากแพลตฟอร์มของเรา เมื่อท่านใช้งานเสร็จ
            (ท่านไม่ควรวางใจให้เรานำท่านออกจากระบบโดยอัตโนมัติ)
          </li>
          <li>หลีกเลี่ยงการใช้ Wi-Fi ที่ไม่ปลอดภัยหรือไม่ได้เข้ารหัสไว้</li>
          <li>
            ใช้เฉพาะฮอตสปอต Wi-Fi สาธารณะหากท่านรู้ว่าจุดดังกล่าวเชื่อถือได้ –
            ผู้โจมตีอาจตั้งค่าเครือข่าย Wi-Fi
            ที่เป็นอันตรายซึ่งอาจดักจับข้อมูลของท่าน
          </li>
        </ul>
        <p>ท่านจะต้องไม่กระทำการต่อไปนี้:</p>
        <ul>
          <li>
            เขียนหรือบันทึกรายละเอียดที่เกี่ยวกับความปลอดภัยของท่านที่ใช้กับแพลตฟอร์มของเรา
            ในลักษณะที่บุคคลอื่นสามารถเข้าใจได้
          </li>
          <li>
            ให้บุคคลอื่นรู้หรือใช้รายละเอียดที่เกี่ยวกับความปลอดภัยของท่าน
          </li>
          <li>
            วางอุปกรณ์ของท่านทิ้งไว้หลังจากที่ลงชื่อเข้าใช้แพลตฟอร์มของเรา&nbsp;&nbsp;
          </li>
          <li>
            ให้บุคคลอื่นใช้อุปกรณ์ของท่านหากท่านได้รับการลงชื่อเข้าใช้แพลตฟอร์มของเรา&nbsp;
            &nbsp;
          </li>
          <li>
            ตั้งค่าคอมพิวเตอร์สาธารณะเป็นคอมพิวเตอร์ที่ท่านต้องการให้เรารับรู้
            เช่น ในอินเทอร์เน็ตคาเฟ่
          </li>
        </ul>
        <p>
          เราสงวนสิทธิ์ในการปิดใช้งานรหัสประจำตัวผู้ใช้หรือรหัสผ่านของท่าน
          หรือการเข้าถึงแพลตฟอร์มของเราหรือบัญชีของท่าน
          ไม่ว่าท่านจะเป็นคนเลือกไว้หรือเรากำหนดให้ได้ตลอดเวลา
          หากเราเห็นโดยมีเหตุผลอันสมควรว่าท่านไม่ปฏิบัติตามข้อใดของข้อตกลงการใช้บริการ
          หรือกฎหมายหรือกฎระเบียบที่เกี่ยวข้อง &nbsp;
        </p>
        <p>
          หากท่านทราบหรือสงสัยว่ามีบุคคลอื่นนอกเหนือจากท่านทราบรหัสประจำตัวผู้ใช้หรือรหัสผ่านของท่าน
          หรืออุปกรณ์ของท่านชำรุด สูญหาย หรือถูกโจรกรรม
          ท่านจะต้องแจ้งให้เราทราบโดยทันทีเพื่อยกเลิกหรือระงับการใช้บริการของเรา
          ที่โทรศัพท์หมายเลข <a href="tel:1397">1397</a> หรือทางอีเมลมาที่&nbsp;
          <a href="mailto:callcenter@bmw.co.th" target="_blank">
            callcenter@bmw.co.th
          </a>
          &nbsp;
          ทั้งนี้&nbsp;เราจะไม่รับผิดในกรณีที่ท่านไม่ได้แจ้งเหตุดังกล่าวข้างต้นให้เราทราบ
        </p>
        <p>&nbsp;</p>
        <p>
          <b>7.&nbsp;การใช้แพลตฟอร์มของเรา</b>
        </p>
        <p>
          การกระทำใด ๆ
          ที่กระทำโดยใช้บัญชีและรหัสผ่านของท่านผ่านทางแพลตฟอร์มของเรา
          ไม่ว่าจะเกี่ยวข้องกับบริการใด ๆ ของเรา
          จะถือว่ามีผลสมบูรณ์และมีผลผูกพันท่านโดยไม่จำเป็นต้องมีลายมือชื่อของท่านในเอกสารใด
          ๆ ของเรา และท่านจะต้องรับผิดชอบต่อการกระทำดังกล่าว อย่างไรก็ตาม
          เราอาจขอเอกสารเพิ่มเติมหากเราเห็นสมควร
        </p>
        <p>
          ในบางกรณี ท่านไม่อาจจะเปลี่ยนแปลง ระงับ
          หรือยกเลิกรายการหักเงินจากบัญชี (direct debit) หรือการชำระเงินใด ๆ
          ภายหลังจากท่านได้ส่งคำขอดังกล่าวให้เราผ่านทางแพลตฟอร์มของเราแล้ว
          หากมีการทำคำขอดังกล่าวไว้ล่วงหน้า ท่านสามารถเปลี่ยนแปลง ระงับ
          หรือยกเลิกคำขอดังกล่าวได้เฉพาะก่อนที่เราจะดำเนินการใดๆ
          ที่เกี่ยวข้องกับคำขอดังกล่าว หรือภายในกรอบเวลาที่เรากำหนด
          ภายใต้ข้อตกลงและเงื่อนไขอื่นๆ ที่เกี่ยวข้อง
        </p>
        <p>
          ท่านตกลงว่า ในการดำเนินการตามคำขอของท่าน
          เราได้ให้บริการแก่ท่านอย่างถูกต้อง
          และการดำเนินการดังกล่าวจะมีผลผูกพันท่าน
        </p>
        <p>
          ท่านรับรองและรับประกันว่าเอกสารและ/หรือหลักฐานต่างๆเกี่ยวกับข้อมูลของท่าน
          หรือการดำเนินการใด ๆ ของเราต่อข้อมูลของท่านและ/หรือหลักฐานใด ๆ
          ของการให้บริการของเราที่ดำเนินการตามคำขอของท่านภายใต้ข้อตกลงการใช้บริการนี้ถูกต้องและมีผลผูกพันท่านทุกประการ
        </p>
        <p>&nbsp;</p>
        <p>
          <b>8.&nbsp;สิทธิในทรัพย์สินทางปัญญา</b>
        </p>
        <p>
          สิทธิทางกฎหมาย ตำแหน่ง ผลประโยชน์และสิทธิในทรัพย์สินทางปัญญาใดๆ
          ที่เกี่ยวข้องกับแพลตฟอร์มและบริการ รวมถึงแต่ไม่จำกัดเพียง ชื่อ โลโก้
          และรูปภาพบนแพลตฟอร์มของเรา ซึ่งระบุถึงบริษัทในกลุ่ม BMW (ประเทศไทย)
          หรือบุคคลภายนอก และผลิตภัณฑ์และบริการของบริษัทและบุคคลดังกล่าว
          เป็นของเราและ/หรือบุคคลภายนอกที่เกี่ยวข้องแต่เพียงผู้เดียว
          เราขอมอบการอนุญาตที่ไม่ผูกขาดและสามารถเพิกถอนได้ในการใช้แพลตฟอร์มและบริการสำหรับการใช้งานส่วนตัวเท่านั้น
          ไม่มีส่วนใดในแพลตฟอร์มของเราที่ถือเป็นการให้อนุญาตหรือให้สิทธิในส่วนของ
          BMW AG หรือบุคคลภายนอกอื่นใดเกี่ยวกับชื่อ โลโก้
          หรือรูปภาพดังกล่าวให้กับบุคคลใดๆ
          เนื่องจากสิทธิดังกล่าวทั้งหมดได้รับการสงวนไว้ &nbsp;
        </p>
        <p>
          ลิขสิทธิ์ในเนื้อหาที่อยู่บนแพลตฟอร์มของเรา เป็นของบริษัทในกลุ่ม
          BMW&nbsp; (ประเทศไทย) หรือ BMW AG ซึ่งเป็นบริษัทแม่ หรือผู้ให้อนุญาต
          ห้ามบุคคลใดคัดลอก ดัดแปลง ส่ง แจกจ่าย แสดง ทำซ้ำ เผยแพร่
          อนุญาตหรือสร้างงานจากส่วนใดส่วนหนึ่งของเนื้อหานี้
          หรือนำไปใช้เพื่อการใช้ประโยชน์สาธารณะหรือในเชิงพาณิชย์โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรอย่างชัดแจ้งล่วงหน้าจากเรา
          ท่านสามารถดูหรือพิมพ์เอกสารแต่ละหน้าได้เพื่อการใช้งานส่วนตัวของท่านเท่านั้น
        </p>
        <p>
          บริการและเทคโนโลยีหรือกระบวนการที่อธิบายไว้บนแพลตฟอร์มของเราอาจอยู่ภายใต้สิทธิในทรัพย์สินทางปัญญาอื่นๆ
          ที่บริษัทในกลุ่ม BMW (ประเทศไทย) หรือผู้ให้อนุญาต
          หรือบุคคลภายนอกอื่นที่เกี่ยวข้องสงวนสิทธิ์ไว้
          และไม่มีการอนุญาตให้ใช้สิทธิในส่วนที่เกี่ยวกับสิทธิในทรัพย์สินทางปัญญานั้น
        </p>
        <p>
          ท่านจะต้องไม่แก้ไขเนื้อหาของสำเนาที่อยู่ในรูปของกระดาษหรือดิจิทัลที่ท่านพิมพ์ออกมาหรือดาวน์โหลดไปในทางใด
          และท่านต้องไม่ใช้ภาพประกอบ รูปถ่าย ลำดับวิดีโอหรือเสียง
          หรือกราฟิกอื่นใดโดยแยกจากข้อความที่เขียนกำกับไว้
          ท่านจะต้องรับทราบว่าเรา &nbsp;(และผู้ร่วมให้ข้อมูล)
          มีสถานะเป็นผู้สร้างสรรค์เนื้อหาบนแพลตฟอร์มของเรา
          ท่านจะต้องไม่ใช้ส่วนใดส่วนหนึ่งของเนื้อหาบนแพลตฟอร์มของเราเพื่อวัตถุประสงค์ในเชิงพาณิชย์โดยไม่ได้รับอนุญาตจากเราหรือผู้ให้อนุญาตของเรา
          &nbsp;
        </p>
        <p>
          หากท่านพิมพ์ คัดลอก
          หรือดาวน์โหลดส่วนหนึ่งส่วนใดของแพลตฟอร์มของเราที่เป็นการละเมิดข้อตกลงการใช้บริการนี้
          สิทธิของท่านในการใช้แพลตฟอร์มของเราจะสิ้นสุดลงทันที
          และท่านจะต้องส่งคืนหรือทำลายสำเนาของเนื้อหาใด ๆ ที่ท่านได้ทำขึ้น&nbsp;
          <a>ตามที่เราเลือก</a>
        </p>
        <p>
          ท่านให้สิทธิอันมิใช่สิทธิแต่เพียงผู้เดียวแต่สามารถใช้ได้ทั่วโลกแก่เราและบริษัทในเครือของเรา
          โดยไม่คิดค่าสิทธิในการใช้ ทำซ้ำ แสดง แก้ไข และจัดรูปแบบเนื้อหาใดๆ
          และทั้งหมดของท่านที่ท่านให้ไว้อย่างเคร่งครัดตามคำแนะนำและ/หรือข้อกำหนด
          เพื่อวัตถุประสงค์ในการดำเนินงานของเรา
          หรือประสิทธิภาพของแพลตฟอร์มและบริการภายใต้ข้อตกลงการใช้บริการนี้
        </p>
        <p>&nbsp;</p>
        <p>
          <b>9.&nbsp;ท่านจะต้องไม่ยึดถือตามข้อมูลเป็นหลัก</b>
        </p>
        <p>
          เนื้อหาบนแพลตฟอร์มของเรา
          รวมถึงข้อมูลใดๆเกี่ยวกับบริการทางการเงินของเรา
          ข้อมูลเกี่ยวกับข้อตกลงใด ๆ หรือข้อมูลอื่น ๆ
          มีไว้เพื่อเป็นข้อมูลทั่วไปเท่านั้น
          และข้อมูลที่จัดเก็บอยู่บนแพลตฟอร์มของเรา
          จะเป็นสำเนาอิเล็กทรอนิกส์ของเอกสารที่เกี่ยวข้องกับข้อตกลงและการติดต่อระหว่างท่านกับเรา
          แต่ไม่ได้มีวัตถุประสงค์เพื่อให้เป็นคำแนะนำที่ท่านควรยึดถือเป็นหลัก
          ท่านต้องได้รับคำแนะนำจากผู้ชำนาญการหรือผู้เชี่ยวชาญก่อนที่จะกระทำการหรืองดเว้นการกระทำใด
          ๆ โดยพิจารณาจากเนื้อหาบนแพลตฟอร์มของเรา &nbsp;
        </p>
        <p>
          แม้ว่าเราจะใช้ความพยายามตามสมควรในปรับปรุงข้อมูลบนแพลตฟอร์มของเราให้เป็นปัจจุบันแต่เราไม่ขอรับประกัน
          รับรองหรือยืนยัน ไม่ว่าโดยชัดแจ้งหรือโดยปริยาย
          ว่าเนื้อหาบนแพลตฟอร์มของเรานั้นถูกต้อง สมบูรณ์ หรือเป็นปัจจุบัน
          ทั้งนี้
          ข้อมูลที่เก็บไว้บนแพลตฟอร์มของเราจะเกี่ยวข้องกับข้อตกลงและ/หรือการติดต่อระหว่างท่านกับเรา
        </p>
        <p>&nbsp;</p>
        <p>
          <b>10.&nbsp;ความรับผิดของเราที่มีต่อท่าน</b>
        </p>
        <p>
          ไม่มีข้อความใดในข้อตกลงการใช้บริการนี้ยกเว้นหรือจำกัดความรับผิดของเราสำหรับกรณีการเสียชีวิตหรือการบาดเจ็บที่เกิดจากความประมาทเลินเล่อของเรา
          หรือการฉ้อโกงหรือการแถลงข้อความที่รู้อยู่แล้วว่าไม่เป็นความจริงโดยมีเจตนาเพื่อฉ้อฉล
          หรือความรับผิดอื่นใดที่ไม่สามารถยกเว้นหรือจำกัดโดยกฎหมายไทย
        </p>
        <p>
          เราจะยกเว้นเงื่อนไข การรับประกัน การรับรอง
          หรือข้อกำหนดอื่นทั้งปวงที่อาจใช้บังคับกับแพลตฟอร์มและบริการของเรา
          หรือเนื้อหาที่อยู่ในนั้น ไม่ว่าจะโดยชัดแจ้งหรือโดยปริยาย ทั้งนี้
          เท่าที่กฎหมายอนุญาตไว้
        </p>
        <p>
          เราและเจ้าหน้าที่ ลูกจ้าง กรรมการ ตัวแทน ผู้รับเหมา
          และผู้รับโอนทั้งหมดของเราจะไม่รับผิดต่อท่านหรือผู้ใช้รายใดสำหรับกรณีการสูญเสียหรือความเสียหาย
          ไม่ว่าจะเป็นความรับผิดทางสัญญา ละเมิด (รวมถึงความประมาทเลินเล่อ)
          การไม่กระทำตามหน้าที่ตามกฎหมาย หรืออื่นๆ แม้ว่าจะคาดการณ์ได้
          ไม่ว่าจะเกิดขึ้นโดยตรงหรือโดยอ้อม ภายใต้หรือเกี่ยวข้องกับ
        </p>
        <ul>
          <li>การใช้หรือไม่สามารถใช้แพลตฟอร์มและ/หรือบริการของเรา</li>
          <li>
            การใช้หรือยืดถือตามเนื้อหาที่แสดงอยู่บนแพลตฟอร์มของเราหรือผ่านบริการ
          </li>
        </ul>
        <p>
          หากท่านเป็นผู้ใช้งานที่เป็นธุรกิจ
          โปรดทราบว่าเราจะไม่รับผิดต่อสิ่งต่อไปนี้
        </p>
        <ul>
          <li>การสูญเสียผลกำไร การขาย ธุรกิจ หรือรายได้</li>
          <li>การหยุดชะงักของธุรกิจ</li>
          <li>การสูญเสียเงินออมที่คาดไว้</li>
          <li>
            การสูญเสียโอกาสทางธุรกิจ ค่าความนิยม หรือชื่อเสียง&nbsp;&nbsp;
          </li>
          <li>การสูญเสียหรือความเสียหายโดยอ้อมหรือที่เป็นผลสืบเนื่อง</li>
        </ul>
        <p>
          หากท่านเป็นผู้ใช้ที่เป็นผู้บริโภค
          ขอเรียนว่าเราจะให้ท่านใช้งานแพลตฟอร์มและบริการของเราสำหรับการใช้ในประเทศและใช้เป็นการส่วนตัวเท่านั้น
          ท่านตกลงที่จะไม่ใช้แพลตฟอร์มของเรา
          เพื่อวัตถุประสงค์ในเชิงพาณิชย์หรือทางธุรกิจ
          และเราจะไม่มีความรับผิดต่อท่านสำหรับการสูญเสียผลกำไร การสูญเสียธุรกิจ
          การหยุดชะงักของธุรกิจ หรือการสูญเสียโอกาสทางธุรกิจ
        </p>
        <p>เราจะไม่รับผิดต่อท่านหรือบุคคลอื่นในกรณีดังต่อไปนี้:</p>
        <ul>
          <li>
            ท่านมีเงินในบัญชีไม่พอ หรือท่านถูกห้ามไม่ให้ทำธุรกรรมใด ๆ กับเรา
          </li>
          <li>
            ธุรกรรมหรือการชำระเงินใด ๆ
            ที่ท่านทำนั้นจะเป็นผลให้ยอดหนี้ในบัญชีเกินกว่าวงเงินที่ท่านมีอยู่กับธนาคาร
            (ถ้ามี)
          </li>
          <li>การกระทำที่เกิดจากการปฏิบัติตามกระบวนการทางกฎหมาย</li>
          <li>
            กรณีที่เราได้แจ้งท่านเรื่องข้อขัดข้องหรือข้อผิดพลาดของบริการ
            หรือการหักบัญชีสำหรับการชำระเงินที่มีอยู่ก่อนแล้วหรือในระหว่างการใช้งานของท่าน
          </li>
          <li>ท่านไม่ปฏิบัติตามหรือปฏิบัติผิดข้อตกลงและเงื่อนไขของเรา</li>
          <li>กรณีที่เป็นเหตุสุดวิสัย</li>
        </ul>
        <p>
          เพื่อวัตถุประสงค์ของข้อตกลงการใช้บริการนี้
          เหตุสุดวิสัยหมายความถึงเหตุใด ๆ
          อันอยู่นอกเหนือการควบคุมของเราซึ่งเป็นอุปสรรคต่อการบริการของเราตามข้อตกลงการใช้บริการนี้
          และให้รวมถึงข้อขัดข้องหรือความผิดปกติของระบบคอมพิวเตอร์ของเรา
          กระแสไฟฟ้าขัดข้อง การกระทำของบุคคลภายนอก
          หรือไวรัสคอมพิวเตอร์ที่ไม่ได้มีสาเหตุมาจากการที่เราไม่มีมาตรการรักษาความปลอดภัยอย่างเพียงพอตามมาตรฐาน
        </p>
        <p>
          เราจะไม่รับผิดต่อความสูญเสียหรือความเสียหายใดๆ ที่เกิดจากไวรัส
          การโจมตีแบบปฏิเสธการให้บริการ
          หรือวัสดุที่เป็นอันตรายในเชิงเทคโนโลยีอื่นที่อาจทำให้อุปกรณ์คอมพิวเตอร์
          โปรแกรมคอมพิวเตอร์ ข้อมูล
          หรือเนื้อหาที่เป็นกรรมสิทธิ์ของท่านติดไวรัสเนื่องจากการใช้แพลตฟอร์มของเรา
          &nbsp;หรือการดาวน์โหลดเนื้อหาจากบนแพลตฟอร์มของเรา
          หรือเว็บไซต์อื่นที่เชื่อมโยงกับแพลตฟอร์มของเรา
        </p>
        <p>
          เราจะไม่รับผิดชอบต่อเนื้อหาของเว็บไซต์ที่เชื่อมโยงอยู่บนแพลตฟอร์มของเรา
          &nbsp;และท่านจะต้องไม่ตีความว่าการเชื่อมโยงไปยังเว็บไซต์ดังกล่าวนั้นได้รับการรับรองจากเรา
          เราจะไม่รับผิดต่อความสูญเสียหรือความเสียหายใดๆ
          ที่อาจเกิดขึ้นจากการที่ท่านใช้งานเว็บไซต์ที่เชื่อมโยงเหล่านั้น
        </p>
        <p>
          เงื่อนไขการใช้บริการนี้ไม่ได้มีจุดมุ่งหมายเพื่อสร้างหรือถูกตีความว่าเป็นการก่อให้เกิดความรับผิดร่วมกันและหลายประการ
          รวมทั้งแต่ละบริษัทในกลุ่ม BMW (ประเทศไทย)
          จะไม่รับผิดต่อการกระทำและการละเว้นการกระทำของบริษัทอื่นในกลุ่ม BMW
          (ประเทศไทย)
        </p>
        <p>&nbsp;</p>
        <p>
          <b>11.&nbsp;การใช้งานที่ยอมรับได้และมาตรฐานเนื้อหา &nbsp;</b>
        </p>
        <p>
          หากเราให้ท่านใช้งานและหากท่านใช้งานฟีเจอร์ใดฟีเจอร์หนึ่งที่อนุญาตให้ท่านอัปโหลดเนื้อหามายังแพลตฟอร์มของเราได้
          หรือเพื่อติดต่อกับผู้ใช้รายอื่นในแพลตฟอร์มของเรา
          ท่านต้องปฏิบัติตามการใช้งานที่ยอมรับได้และมาตรฐานเนื้อหาตามที่กำหนดไว้ด้านล่าง
        </p>
        <p>
          <b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            I.&nbsp;การใช้งานที่พึงต้องห้าม
          </b>
        </p>
        <ul>
          <li>
            ท่านสามารถใช้แพลตฟอร์มและบริการของเราเพื่อวัตถุประสงค์ที่ชอบด้วยกฎหมายเท่านั้น
            และห้ามใช้แพลตฟอร์มของเราในลักษณะดังนี้<b></b>
          </li>
          <li>
            ในทางที่เป็นการฝ่าฝืนกฎหมายหรือกฎระเบียบในระดับท้องถิ่น
            ระดับประเทศหรือระดับสากล และข้อตกลงการใช้บริการนี้
          </li>
          <li>
            ในทางที่ผิดกฎหมายหรือฉ้อฉล
            หรือมีวัตถุประสงค์หรือผลที่ไม่ชอบด้วยกฎหมายหรือฉ้อฉล
          </li>
          <li>
            เพื่อวัตถุประสงค์ในการทำร้ายหรือพยายามจะทำร้ายผู้เยาว์ในทางใดทางหนึ่ง
          </li>
          <li>
            เพื่อส่ง
            หรือจัดให้มีการส่งโฆษณาหรือสื่อส่งเสริมการขายที่ไม่พึงประสงค์หรือไม่ได้รับอนุญาต
            หรือการชักชวนในลักษณะคล้ายกันในรูปแบบอื่นใด (สแปม)
          </li>
          <li>
            เพื่อส่งผ่านข้อมูล ส่งหรืออัปโหลดเนื้อหาที่มีไวรัส ม้าโทรจัน เวิร์ม
            ไทม์บอมบ์ คีย์สโตรกล็อกเกอร์ สปายแวร์ แอดแวร์
            หรือโปรแกรมที่เป็นอันตรายประเภทอื่น
            หรือรหัสคอมพิวเตอร์ที่คล้ายกันซึ่งได้รับการออกแบบมาเพื่อสร้างผลเสียร้ายแรงต่อการทำงานของซอฟต์แวร์หรือฮาร์ดแวร์ของคอมพิวเตอร์
          </li>
        </ul>
        <p>ท่านตกลงว่า</p>
        <ul>
          <li>
            จะไม่ทำซ้ำ คัดลอก ทำสำเนา
            หรือขายต่อส่วนหนึ่งส่วนใดของแพลตฟอร์มของเรา
            โดยฝ่าฝืนข้อกำหนดของข้อตกลงการใช้บริการนี้
          </li>
          <li>
            ไม่เข้าถึงโดยไม่ได้รับอนุญาต รวมถึงไม่แทรกแซง ทำลาย หรือขัดขวาง
            <br />
            o&nbsp;&nbsp; ส่วนหนึ่งส่วนใดของแพลตฟอร์มหรือบริการของเรา
            <br />
            o&nbsp;&nbsp; อุปกรณ์หรือระบบเครือข่ายที่จัดเก็บแพลตฟอร์มของเราไว้
            <br />
            o&nbsp;&nbsp; ซอฟต์แวร์ที่ใช้ในการให้บริการแพลตฟอร์มหรือบริการของเรา
            <br />
            o&nbsp;&nbsp;&nbsp;อุปกรณ์ ระบบเครือข่าย
            หรือซอฟต์แวร์ที่บุคคลภายนอกเป็นเจ้าของหรือใช้งาน
          </li>
        </ul>
        <p>
          <b>
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; II.&nbsp;บริการเชิงโต้ตอบ
          </b>
          <br />
        </p>
        <p>
          เราอาจให้บริการเชิงโต้ตอบบนแพลตฟอร์มของเราเป็นครั้งคราว
          ซึ่งรวมถึงแต่ไม่จำกัดเพียง
        </p>
        <ul>
          <li>ห้องสนทนา และแชทบอท</li>
          <li>กระดานข่าว</li>
        </ul>
        <p>
          (“<b>บริการเชิงโต้ตอบ</b>”)
        </p>
        <p>
          ในกรณีที่เราให้บริการเชิงโต้ตอบ
          เราจะให้ข้อมูลที่ชัดเจนแก่ท่านเกี่ยวกับประเภทของบริการที่เสนอ
          มีการกลั่นกรองหรือไม่ และเป็นการกลั่นกรองในรูปแบบใด
          (ไม่ว่าจะใช้คนหรือเทคนิคในการกลั่นกรอง)
        </p>
        <p>
          เราจะพยายามอย่างเต็มที่เพื่อประเมินความเสี่ยงที่อาจเกิดขึ้นกับผู้ใช้
          (และเด็กโดยเฉพาะอย่างยิ่ง)
          จากบุคคลภายนอกเมื่อใช้บริการเชิงโต้ตอบที่อยู่บนแพลตฟอร์มของเรา
          และเราจะตัดสินใจในแต่ละกรณีว่าเหมาะสมหรือไม่ที่จะใช้การกลั่นกรองสำหรับบริการนั้น
          (รวมถึงวิธีการกลั่นกรองที่จะใช้) โดยพิจารณาถึงความเสี่ยงดังกล่าว
          อย่างไรก็ตาม เราไม่มีหน้าที่ในการดูแล
          เฝ้าสังเกตหรือกลั่นกรองบริการเชิงโต้ตอบที่อยู่บนแพลตฟอร์มของเรา
          และเรายกเว้นความรับผิดโดยชัดแจ้งต่อความสูญเสียหรือความเสียหายใดๆ
          ที่เกิดจากการใช้บริการเชิงโต้ตอบของผู้ใช้ที่ขัดต่อมาตรฐานเนื้อหา
          ไม่ว่าแพลตฟอร์มหรือบริการของเราจะมีการกลั่นกรองหรือไม่ก็ตาม
        </p>
        <p>
          ในกรณีที่เรากลั่นกรองบริการแบบโต้ตอบ
          โดยปกติเราจะให้วิธีการติดต่อกับผู้กลั่นกรองแก่ท่าน
          หากมีข้อกังวลหรือมีปัญหาเกิดขึ้น
        </p>
        <p>
          <b>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;III.&nbsp;มาตรฐานเนื้อหา</b>
        </p>
        <p>
          มาตรฐานเนื้อหานี้จะใช้บังคับกับเนื้อหาใดทั้งปวงที่ท่านส่งมายังแพลตฟอร์มของเรา
          หรือที่สื่อสารผ่านทางแพลตฟอร์มของเรา &nbsp;(“
          <b>เนื้อหาที่ท่านส่งมา</b>”) และบริการเชิงโต้ตอบใดๆ ที่เกี่ยวข้อง
        </p>
        <p>
          ท่านต้องปฏิบัติตามเจตนารมณ์และตัวอักษรของมาตรฐานต่อไปนี้
          ซึ่งมาตรฐานนี้จะใช้กับเนื้อหาที่ท่านส่งมาบางส่วนและทั้งหมด
        </p>
        <p>เนื้อหาที่ท่านส่งมาจะต้องมีลักษณะดังนี้:</p>
        <ul>
          <li>
            ถูกต้อง (เมื่อเป็นการระบุข้อเท็จจริง)
            ในระดับที่เราไม่ต้องตรวจสอบข้อเท็จจริงดังกล่าวกับแหล่งข้อมูลอื่น
          </li>
          <li>
            เป็น<a>เนื้อหา</a>ที่จริงใจ (ในกรณีที่แสดงความเห็น)
          </li>
          <li>มีผลผูกพันเท่าที่บังคับได้ตามกฎหมาย</li>
          <li>
            เป็นไปตามกฎหมายที่บังคับใช้ในประเทศไทยและในประเทศใดๆ
            ที่มีการโพสต์ข้อความ
          </li>
        </ul>
        <p>เนื้อหาที่ท่านส่งมาจะต้องไม่มีลักษณะดังนี้:</p>
        <ul>
          <li>มีเนื้อหาที่เป็นการหมิ่นประมาทบุคคล</li>
          <li>มีเนื้อหาลามกอนาจาร ล่วงละเมิด แสดงความเกลียดชังหรือยั่วยุ</li>
          <li>ส่งเสริมเนื้อหาทางเพศที่โจ่งแจ้ง</li>
          <li>ส่งเสริมความรุนแรง</li>
          <li>
            ส่งเสริมการเลือกปฏิบัติโดยพิจารณาจากเชื้อชาติ เพศ ศาสนา สัญชาติ
            ความพิการ รสนิยมทางเพศ หรืออายุ
          </li>
          <li>
            ละเมิดลิขสิทธิ์ สิทธิในฐานข้อมูล หรือเครื่องหมายการค้าของบุคคลอื่น
          </li>
          <li>มีแนวโน้มว่าจะเป็นการหลอกลวงบุคคลอื่น</li>
          <li>
            ไม่ปฏิบัติตามหน้าที่ทางกฎหมายที่มีต่อบุคคลภายนอก เช่น
            หน้าที่ตามสัญญาหรือหน้าที่ในการรักษาความลับ
          </li>
          <li>สนับสนุนกิจกรรมที่ผิดกฎหมาย</li>
          <li>
            ข่มขู่ ล่วงละเมิด หรือ บุกรุกความเป็นส่วนตัวของผู้อื่น
            หรือก่อให้เกิดความรำคาญ ความไม่สะดวก หรือความวิตกกังวลโดยไม่จำเป็น
          </li>
          <li>
            มีแนวโน้มที่จะก่อกวน หรือทำให้บุคคลอื่นกลัดกลุ้ม อับอาย ตื่นตระหนก
            หรือรบกวนบุคคลอื่น
          </li>
          <li>
            ใช้เพื่อแอบอ้างเป็นบุคคลอื่น
            หรือบิดเบือนตัวตนของท่านหรือความเกี่ยวข้องกับบุคคลใดๆ
          </li>
          <li>ทำให้เข้าใจว่าเป็นเนื้อหาที่มาจากเรา</li>
          <li>
            สนับสนุน ส่งเสริม หรือช่วยเหลือการกระทำที่ผิดกฎหมาย เช่น
            การละเมิดลิขสิทธิ์ หรือการใช้คอมพิวเตอร์ในทางที่ผิด
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <b>12. การระงับและการยกเลิกการให้บริการ</b>
        </p>
        <p>
          เราจะพิจารณาตามดุลยพินิจของเราว่ามีการละเมิดการใช้งานที่ยอมรับได้และมาตรฐานของเนื้อหาในการใช้แพลตฟอร์มหรือบริการของเราหรือไม่
          ซึ่งเมื่อมีการละเมิดเกิดขึ้น
          เราอาจดำเนินการตามที่เห็นสมควรโดยเราไม่จำเป็นต้องแจ้งให้ท่านทราบ
        </p>
        <p>
          การไม่ปฏิบัติตามการใช้งานที่ยอมรับได้และมาตรฐานของเนื้อหาของเรา
          ถือเป็นการละเมิดข้อตกลงการใช้บริการนี้อย่างมีสาระสำคัญ
          เนื่องจากเป็นข้อตกลงที่อนุญาตให้ท่านใช้แพลตฟอร์มและบริการของเรา
          และอาจทำให้เราดำเนินการใดๆ หรือทั้งหมด ดังต่อไปนี้
        </p>
        <ul>
          <li>
            เพิกถอนสิทธิของท่านในการใช้แพลตฟอร์มและบริการของเราโดยทันที
            ไม่ว่าจะเป็นการชั่วคราวหรือถาวร
          </li>
          <li>
            ลบโพสต์หรือเนื้อหาที่ท่านอัปโหลดไปยังแพลตฟอร์มของเราโดยทันที
            ไม่ว่าจะเป็นการชั่วคราวหรือถาวร
          </li>
          <li>ส่งคำเตือนถึงท่าน</li>
          <li>
            ดำเนินกระบวนการทางกฎหมายกับท่านเพื่อให้ชดใช้ค่าใช้จ่ายทั้งปวงที่เป็นการชดใช้ความเสียหาย
            (รวมถึงและไม่จำกัดเพียงค่าใช้จ่ายในการดำเนินการและค่าใช้จ่ายทางกฎหมายในอัตราตามสมควร)
            ที่เกิดจากการละเมิดนั้น
          </li>
          <li>การดำเนินการทางกฎหมายเพิ่มเติมกับท่าน</li>
          <li>
            เปิดเผยข้อมูลดังกล่าวต่อหน่วยงานบังคับใช้กฎหมายตามที่เราเห็นว่าจำเป็น
          </li>
        </ul>
        <p>
          เราจะไม่รับผิดเกี่ยวกับการกระทำที่ได้ทำขึ้นเพื่อโต้ตอบกับการละเมิดการใช้งานที่ยอมรับได้และมาตรฐานของเนื้อหาของเรา
          เราอาจทำการโต้ตอบนอกเหนือจากการโต้ตอบที่ระบุไว้ข้างต้น
          และเราอาจดำเนินการอื่นใดที่เราเห็นว่าเหมาะสม
        </p>
        <p>
          ท่านสามารถยกเลิกการใช้บริการของเราได้โดยแจ้งเป็นลายลักษณ์อักษรล่วงหน้าไม่น้อยกว่าสิบห้า
          (15) วันก่อนที่การยกเลิกดังกล่าวจะมีผล ผ่านช่องทางการติดต่อของเรา
          ทั้งนี้ เราสงวนสิทธิ์ในการเรียกเก็บค่าธรรมเนียม ค่าบริการ
          หรือค่าใช้จ่ายใด ๆ
          อันเกี่ยวกับการใช้บริการของเราก่อนการยกเลิกการใช้บริการจากบัญชีของท่าน
          หากมี
        </p>
        <p>&nbsp;</p>
        <p>
          <b>
            13.&nbsp;การเปลี่ยนแปลงการใช้งานที่ยอมรับได้และมาตรฐานของเนื้อหา
          </b>
        </p>
        <p>
          เราอาจเปลี่ยนแปลงการใช้งานที่ยอมรับได้และมาตรฐานของเนื้อหาของเราได้ตลอดเวลา
          โดยการแก้ไขเพิ่มเติมหน้านี้ ดังนั้น
          โปรดตรวจดูหน้านี้เป็นครั้งคราวเพื่อที่ท่านจะได้รับทราบการเปลี่ยนแปลงใดๆ
          ที่เราทำขึ้น เนื่องจากจะมีผลผูกพันท่านในทางกฎหมาย ทั้งนี้
          ข้อกำหนดบางประการในการใช้งานที่ยอมรับได้และมาตรฐานของเนื้อหาของเราอาจถูกแทนที่ด้วยข้อกำหนดหรือประกาศที่เผยแพร่ในหน้าอื่นบนแพลตฟอร์มของเรา
        </p>
        <p>
          ท่านรับประกันว่าเนื้อหาที่ท่านส่งมาสอดคล้องกับมาตรฐานดังกล่าว
          และท่านจะรับผิดและชดใช้ค่าเสียหายให้เราหากไม่เป็นไปตามคำรับประกันนั้น
          ซึ่งหมายความว่าท่านจะต้องรับผิดชอบต่อความสูญเสียหรือความเสียหายที่เราได้รับอันเป็นผลมาจากการไม่เป็นไปตามคำรับประกันของท่าน
        </p>
        <p>&nbsp;</p>
        <p>
          <b>14.&nbsp;ไวรัส และข้อผิดพลาด</b>
        </p>
        <p>
          เราไม่ยืนยันว่าแพลตฟอร์มหรือบริการของเราจะปลอดภัยหรือปราศจากข้อบกพร่อง
          (บั๊ก) ไวรัส หรือข้อผิดพลาด
        </p>
        <p>
          ท่านมีหน้าที่รับผิดชอบในการกำหนดค่าเทคโนโลยีสารสนเทศ
          โปรแกรมคอมพิวเตอร์ และแพลตฟอร์มของท่านเพื่อที่จะเข้าถึงแพลตฟอร์มของเรา
          และท่านควรใช้ซอฟต์แวร์ป้องกันไวรัสของท่านเอง
        </p>
        <p>
          ท่านต้องไม่ใช้แพลตฟอร์มหรือบริการของเราในทางที่ผิดโดยนำไวรัส โทรจัน
          เวิร์ม ลอจิกบอมบ์ หรือวัสดุอื่นๆ
          ที่เป็นอันตรายหรือเป็นอันตรายทางเทคโนโลยีมาใช้
          และท่านต้องไม่พยายามเข้าถึงแพลตฟอร์มของเรา
          เซิร์ฟเวอร์ที่จัดเก็บแพลตฟอร์มของเรา หรือเซิร์ฟเวอร์ คอมพิวเตอร์
          หรือฐานข้อมูลใด ๆ ที่เชื่อมต่อกับแพลตฟอร์มของเราโดยไม่ได้รับอนุญาต
          ท่านต้องไม่โจมตีแพลตฟอร์มของเราผ่านการโจมตีแบบปฏิเสธการให้บริการ
          หากฝ่าฝืนข้อกำหนดนี้
          เราอาจดำเนินการทางกฎหมายกับท่านและสิทธิของท่านในการใช้แพลตฟอร์มของเราจะสิ้นสุดลงทันที
        </p>
        <p>
          ท่านต้องแจ้งให้เราทราบทันทีหากท่านพบข้อบกพร่อง (บั๊ก) ไวรัส ข้อผิดพลาด
          หรือความผิดปกติใด ๆ ในการใช้บริการของเรา
          การแจ้งเตือนดังกล่าวจะต้องส่งผ่านช่องทางการติดต่อของเรา
          และอย่างน้อยต้องประกอบด้วยข้อมูลของบริการที่เกี่ยวข้องหรือได้รับผลกระทบ
          ท่านตกลงที่จะร่วมมือกับเราในการตรวจสอบข้อเท็จจริงของเหตุการณ์ดังกล่าว
          เราจะแจ้งผลการตรวจสอบข้อเท็จจริงให้ท่านทราบผ่านวิธีการติดต่อที่เราเลือก
        </p>
        <p>&nbsp;</p>
        <p>
          <b>15.&nbsp;การเชื่อมโยงไปยังแพลตฟอร์มของเรา &nbsp;</b>
        </p>
        <p>ท่านต้องไม่สร้างลิงก์ไปยังแพลตฟอร์มของเราในเว็บไซต์อื่น</p>
        <p>&nbsp;</p>
        <p>
          <b>16.&nbsp;ลิงก์และทรัพยากรของบุคคลภายนอกในแพลตฟอร์มของเรา &nbsp;</b>
        </p>
        <p>
          ในกรณีที่แพลตฟอร์มของเรามีลิงก์ไปยังเว็บไซต์และทรัพยากรอื่นของบุคคลภายนอก
          ลิงก์เหล่านี้มีไว้เพื่อเป็นข้อมูลให้ท่านทราบเท่านั้นและไม่ได้หมายความว่าเรารับรองหรืออนุมัติเว็บไซต์ที่เชื่อมโยงไปนั้น
          อีกทั้ง เราไม่สามารถควบคุมเนื้อหาของเว็บไซต์หรือทรัพยากรนั้นได้
          และเราจะไม่รับผิดชอบต่อเนื้อหาหรือกิจกรรมใดๆ
          บนเว็บไซต์หรือทรัพยากรเหล่านั้น โดยในการใช้แพลตฟอร์มของเรา
          ท่านตกลงว่าการใช้เว็บไซต์และทรัพยากรอื่นๆ
          ที่บุคคลภายนอกให้มานั้นเป็นความเสี่ยงของท่านเองทั้งสิ้น
        </p>
        <p>&nbsp;</p>
        <p>
          <b>17.&nbsp;การรักษาความลับ</b>
        </p>
        <p>
          ท่านจะเก็บรักษาข้อมูลทั้งหมดที่เกี่ยวข้องกับแพลตฟอร์ม บริการ ผลิตภัณฑ์
          การดำเนินธุรกิจ การตลาดและแผนโปรโมชัน หรือการดำเนินงานต่างๆ
          รวมถึงบริษัทที่เกี่ยวข้องไว้เป็นความลับ
          ซึ่งได้เปิดเผยให้แก่ท่านโดยเราหรือในนามของเรา
          (ไม่ว่าจะโดยวาจาหรือเป็นลายลักษณ์อักษร
          และไม่ว่าจะก่อนหรือหลังจากวันที่ของข้อตกลงการใช้บริการนี้)
          หรือข้อมูลใดๆ
          ที่ท่านรับมาทั้งทางตรงและทางอ้อมจากเราหรือบริษัทในเครือของเรา
          หรือข้อมูลต่างๆ ที่ปรากฏตามข้อตกลงการใช้บริการนี้
          ท่านจะรับรองว่าท่านจะใช้ข้อมูลที่เป็นความลับเหล่านี้เพื่อการใช้บริการเท่านั้น
          และจะไม่เปิดเผยข้อมูลดังกล่าวต่อบุคคลภายนอกหรือใช้เพื่อวัตถุประสงค์อื่นใด
          โดยปราศจากความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจากเรา
        </p>
        <p>เพื่อหลีกเลี่ยงข้อสงสัย ข้อมูลที่เป็นความลับไม่รวมถึงข้อมูลที่</p>
        <ul>
          <li>
            สามารถเข้าถึงและเป็นที่รับรู้ต่อสาธารณะอยู่แล้ว ณ
            เวลาที่ทำการเปิดเผย นอกเหนือจากการเปิดเผยข้อมูลโดยฝ่ายผู้รับข้อมูล
          </li>
          <li>
            เป็นข้อมูลที่ฝ่ายผู้รับข้อมูลเป็นเจ้าของก่อนหน้าที่จะถูกมอบข้อมูลนั้นๆ
            โดยฝ่ายผู้รับข้อมูลไม่ทราบว่าแหล่งข้อมูลนั้นๆ
            จะมีผลผูกพันโดยสัญญาการรักษาข้อมูลที่เป็นความลับ หรือหน้าที่อื่นๆ
            ตามสัญญาหรือตามกฎหมาย หรือความไว้วางใจอื่นๆ
            ของการรักษาความลับที่มีต่อฝ่ายผู้เปิดเผยข้อมูล
            หรือบุคคลที่เกี่ยวข้องกับข้อมูลนั้นๆ หรือ
          </li>
          <li>
            สามารถเข้าถึงได้โดยฝ่ายผู้รับข้อมูลบนฐานที่ไม่เป็นความลับจากแหล่งข้อมูลที่มิใช่ฝ่ายผู้เปิดเผยข้อมูล
            โดยฝ่ายผู้รับข้อมูลไม่ทราบว่าแหล่งข้อมูลนั้น
            จะมีผลผูกพันโดยสัญญาการรักษาข้อมูลที่เป็นความลับ หรือหน้าที่อื่นๆ
            ตามสัญญาหรือตามกฎหมาย หรือความไว้วางใจอื่นๆ
            ของการรักษาความลับที่มีต่อฝ่ายผู้เปิดเผยข้อมูลที่เกี่ยวข้องกับข้อมูลนั้น
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <b>18. ข้อมูลส่วนบุคคล &nbsp;&nbsp;</b>
        </p>
        <p>
          ท่านรับประกันและรับรองว่าท่านได้อ่านและเข้าใจนโยบายความเป็นส่วนตัวของเรา
          ซึ่งกำหนดข้อกำหนดต่างในการที่เราใช้หรือเปิดเผยข้อมูลส่วนบุคคลที่เราเก็บรวบรวมจากท่าน
          หรือข้อมูลที่ท่านมอบให้เราที่
        </p>
        <ul>
          <li>
            <a href="https://www.bmw.co.th/th/footer/legal-information/privacy-policy.html">
              https://www.bmw.co.th/th/footer/legal-information/privacy-policy.html
            </a>
          </li>
          <li>
            <a href="https://www.mini.co.th/en_TH/home/footer/privacy-policy.html">
              https://www.mini.co.th/en_TH/home/footer/privacy-policy.html
            </a>
          </li>
          <li>
            <a href="https://www.bmw-motorrad.co.th/th/public-pool/content-pool/privacy.html">
              https://www.bmw-motorrad.co.th/th/public-pool/content-pool/privacy.html
            </a>
          </li>
        </ul>
        <p>
          ท่านรับประกันและรับรองต่อเราว่าข้อมูลใดๆ (รวมถึงข้อมูลส่วนบุคคล)
          ที่ท่านมอบให้แก่เรานั้นเป็นความจริง ถูกต้อง เป็นปัจจุบัน และสมบูรณ์
          และท่านจะแจ้งให้เราทราบหากมีการเปลี่ยนแปลงใดๆ ซึ่งข้อมูลดังกล่าว
        </p>
        <p>
          เมื่อใดที่ท่านมอบข้อมูลส่วนบุคคลของบุคคลอื่นใดให้แก่เรา
          ท่านรับประกันและรับรองต่อเราว่า
        </p>
        <ul>
          <li>
            เจ้าของข้อมูลส่วนบุคคลนั้นได้รับทราบถึงการเก็บรวบรวม ใช้ เปิดเผย
            และ/หรือโอนไปยังต่างประเทศซึ่งข้อมูลส่วนบุคคลภายใต้ข้อตกลงการใช้บริการนี้
          </li>
          <li>
            ท่านได้รับความยินยอมหรืออาศัยฐานทางกฎหมายอื่นในการเก็บรวบรวม ใช้
            เปิดเผย
            และ/หรือโอนไปยังต่างประเทศซึ่งข้อมูลส่วนบุคคลของบุคคลดังกล่าว
            โดยปฏิบัติตามกฎหมายที่เกี่ยวข้อง
          </li>
          <li>
            ท่านได้รับอนุญาตให้เปิดเผยข้อมูลส่วนบุคคลดังกล่าวแก่เรา
            เพื่อที่เราจะดำเนินการเก็บรวบรวม ใช้ เปิดเผย
            และ/หรือโอนไปยังต่างประเทศซึ่งข้อมูลส่วนบุคคล
            เพื่อวัตถุประสงค์ภายใต้ข้อตกลงการใช้บริการนี้และนโยบายความเป็นส่วนตัวที่เกี่ยวข้อง
          </li>
        </ul>
        <p>
          ภายใต้นโยบายความเป็นส่วนตัวของเรา ท่านรับทราบและตกลงให้เราบันทึก
          จัดเก็บ หรือดำเนินการเกี่ยวกับบทสนทนา การใช้แพลตฟอร์มของเรา
          การใช้บริการของเรา
          และข้อมูลของท่านเพื่อวัตถุประสงค์ในการปรับปรุงบริการของเรา
          และ/หรือเพื่อปรับปรุงข้อมูลของท่านให้เป็นปัจจุบัน
          และการบันทึกดังกล่าวสามารถใช้เป็นพยานหลักฐานอ้างอิงต่อท่านในการดำเนินการทางกฎหมายใด
          ๆ ได้ และท่านจะไม่โต้แย้งด้วยประการใด ๆ
          ที่เกี่ยวข้องกับการบันทึกดังกล่าว
          เราไม่มีหน้าที่ต้องบันทึกหรือเก็บรักษาบันทึกบทสนทนาของเราหรือข้อมูลของท่าน
          เท่าที่กฎหมายคุ้มครองข้อมูลจะอนุญาต
        </p>
        <p>
          ท่านตกลงว่าเราสามารถส่งและ/หรือเปิดเผยข้อมูลที่มิใช่ข้อมูลส่วนบุคคล
          หรือข้อมูลที่ไม่สามารถระบุตัวตนใด ๆให้แก่ Google บริษัทในกลุ่มของ
          Google &nbsp;และ/หรือบุคคลหรือนิติบุคคลใดๆ
          ที่เราเป็นคู่สัญญาหรือมีความสัมพันธ์ด้วยทั้งในประเทศไทยและในต่างประเทศ
          เพื่อวัตถุประสงค์ในการรวบรวม วิเคราะห์ และประมวลผลข้อมูล
        </p>
        <p>
          ทั้งนี้
          ท่านสามารถศึกษารายละเอียดการดำเนินการส่งและ/หรือเปิดเผยข้อมูลให้แก่
          Google รวมทั้งการวิเคราะห์ข้อมูลของ Google ได้จากหัวข้อ “How Google
          uses data when you use our partners’ sites or apps” ที่&nbsp;
          <a
            href="http://www.google.com/policies/privacy/partners/"
            target="_blank"
          >
            www.google.com/policies/privacy/partners/
          </a>
          &nbsp; หรือ URL อื่นตามที่ Google กำหนด
        </p>
        <p>
          นอกจากนี้ เราอาจรวมเนื้อหาของบุคคลภายนอกเข้ามาในแพลตฟอร์มของเรา เช่น
          การแสดงวิดีโอ YouTube เรายังทำงานร่วมกับบุคคลภายนอก เช่น
          บริษัทวิเคราะห์ข้อมูลและพันธมิตรทางธุรกิจ (เช่น Salesforce, Google,
          Adobe, Facebook, Instagram, ฯลฯ)
          ซึ่งอาจตั้งค่าคุกกี้หรือเทคโลโลยีตรวจจับอื่น ๆ บนแพลตฟอร์มของเรา
          โดยบุคคลภายนอกเหล่านี้อาจใช้คุกกี้และเทคโนโลยีอื่น (รวมถึง Google
          Analytics, Adobe Analytics, ฯลฯ)
          เพื่อเก็บข้อมูลการใช้งานแพลตฟอร์มออนไลน์ของท่าน
          ข้อมูลนี้อาจถูกใช้ในการวิเคราะห์การใช้งานแพลตฟอร์มและเว็บไซต์ของเรา
          และเพื่อแสดงผลเนื้อหาโฆษณาบนเว็บไซต์ของเราให้ตรงกับความต้องการของท่าน
        </p>
        <p>
          บุคคลภายนอกเหล่านี้เป็นผู้ควบคุมและรับผิดชอบคุกกี้ที่ตั้งค่าคุกกี้ของพวกเขาบนเว็บไซต์ของเรา
          และเราไม่สามารถเข้าถึงหรือควบคุมคุกกี้เหล่านั้น หรือฟีเจอร์อื่น ๆ
          ที่บุคคลภายนอกอาจใช้
          ขั้นตอนการใช้ข้อมูลของบุคคลเหล่านี้ไม่ได้อยู่ในบังคับนโยบายความเป็นส่วนตัวของเรา
          ดังนั้นหากท่านต้องการข้อมูลเพิ่มเติม
          ท่านสามารถศึกษาได้จากนโยบายความเป็นส่วนตัวของบุคคลภายนอกเหล่านั้น
        </p>
        <p>
          สำหรับข้อมูลเพิ่มเติมเกี่ยวกับการทำงานของ Google Analytics และ Adobe
          ท่านสามารถศึกษานโยบายความเป็นส่วนตัวได้ที่ &nbsp;
          <a href="https://policies.google.com/privacy" target="_blank">
            https://policies.google.com/privacy
          </a>
        </p>
        <p>
          &nbsp;และ&nbsp;
          <a href="https://www.adobe.com/privacy/policy.html" target="_blank">
            https://www.adobe.com/privacy/policy.html
          </a>
          .&nbsp;
        </p>
        <p>
          หากท่านไม่ต้องการใช้งานฟีเจอร์การตลาดเหล่านี้
          ท่านสามารถปิดการทำงานได้โดยการเปลี่ยนแปลงการตั้งค่าที่นโยบายความเป็นส่วนตัวของบุคคลภายนอกเหล่านั้น
        </p>
        <p>&nbsp;</p>
        <p>
          <b>19.&nbsp;การโอนสิทธิเรียกร้อง</b>
        </p>
        <p>
          ท่านไม่สามารถโอนข้อตกลงการใช้บริการนี้ หรือสิทธิหรือหน้าที่ใดๆ
          ภายใต้ข้อตกลงการใช้บริการนี้ ไม่ว่าทั้งหมดหรือบางส่วน
          โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจากเรา ทั้งนี้
          เราอาจโอนข้อตกลงการใช้บริการนี้ หรือสิทธิหรือหน้าที่ใดๆ
          ภายใต้ข้อตกลงการใช้บริการนี้ ไม่ว่าทั้งหมดหรือบางส่วนไปให้ผู้อื่น
          และท่านตกลงที่จะยินยอมและให้ความช่วยเหลือและให้การสนับสนุนที่จำเป็นทั้งหมด
          รวมถึงการดำเนินการด้านเอกสารทั้งหมดที่จำเป็นเมื่อเราร้องขอ
        </p>
        <p>&nbsp;</p>
        <p>
          <b>20.&nbsp;กฎหมายที่ใช้บังคับ</b>
        </p>
        <p>
          ข้อตกลงการใช้บริการนี้จัดทำขึ้นเป็นภาษาไทยและภาษาอังกฤษ
          ในกรณีที่มีข้อขัดแย้งระหว่างฉบับภาษาไทยและฉบับภาษาอังกฤษ
          ให้ข้อตกลงการใช้บริการฉบับภาษาอังกฤษมีผลใช้บังคับและมีผลบังคับเหนือฉบับภาษาไทย
        </p>
        <p>
          ข้อตกลงการใช้บริการนี้อยู่ภายใต้บังคับและการตีความของกฎหมายแห่งราชอาณาจักรไทย
          ข้อพิพาทใดๆ
          ที่เกิดขึ้นจากข้อกำหนดและเงื่อนไขฉบับนี้จะถูกชี้ขาดโดยศาลไทยผู้มีอำนาจชี้ขาดประเด็นข้อพิพาททั้งปวง
        </p>
        <p>&nbsp;</p>
        <p>
          <b>21.&nbsp;การส่งคำบอกกล่าว</b>
        </p>
        <p>
          ท่านรับทราบและตกลงว่า ท่านสามารถระบุได้เพียงหนึ่ง (1) หมายเลขโทรศัพท์
          และหนึ่ง (1) อีเมล เพื่อใช้เป็นช่องทางในการติดต่อสื่อสารกับเรา
          หรือเพื่อใช้บริการของเราเท่านั้น
        </p>
        <p>
          ท่านตกลงที่จะแจ้งให้เราทราบทันทีเป็นลายลักษณ์อักษรผ่านช่องทางการติดต่อของเรา
          หากท่านเปลี่ยนแปลงที่อยู่ หมายเลขโทรศัพท์ โทรสาร หรืออีเมล
          สำหรับติดต่อของท่าน
        </p>
        <p>
          เอกสารหรือหนังสือใดๆ ที่เราส่งไปยังท่านหรือผู้ติดต่อประสานงาน (Contact
          Person) (ถ้ามี) ตามที่อยู่ และ/หรือ E-mail Address
          สำหรับติดต่อที่ท่านให้ไว้
          จะถือว่ามีผลและท่านได้ทราบข้อความในเอกสารหรือหนังสือนั้นๆ แล้ว
        </p>
        <p>&nbsp;</p>
        <p>
          <b>22.&nbsp;ติดต่อเรา</b>
        </p>
        <p>
          เราหวังว่าท่านจะชื่นชอบการใช้บริการของเราและเราจะพยายามอย่างเต็มที่เพื่อส่งมอบบริการที่จะช่วยเสริมประสบการณ์ของท่านในการโต้ตอบกับเรา
          หากท่านต้องการติดต่อเราเกี่ยวกับข้อตกลงการใช้บริการนี้
          ท่านสามารถทำได้โดยใช้รายละเอียดต่อไปนี้
        </p>
        <ul>
          <li>ส่งข้อความที่ปลอดภัยถึงเราผ่านแพลตฟอร์มของเรา&nbsp;&nbsp;</li>
          <li>ส่งอีเมลมาที่callcenter@bmw.co.th</li>
          <li>ติดต่อผ่านหมายเลขโทรศัพท์ที่ 1397</li>
          <li>
            ส่งจดหมายถึงเราที่ บริษัท บีเอ็มดับเบิลยู (ประเทศไทย) จำกัด 87/2
            ชั้น 44, 50 และ 51 อาคารซีอาร์ซี ทาวเวอร์ ออล ซีซั่นส์ เพลส ถนนวิทยุ
            แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <b>
            เครื่องมือประกอบยานพาหนะ BMW (BMW Configurator) /
            เครื่องมือประกอบยานพาหนะ MINI (MINI Configurator)
          </b>
        </p>
        <p>
          1. ข้อตกลงและเงื่อนไขเหล่านี้มีผลบังคับกับการใช้งานเว็บไซต์
          เครื่องมือประกอบยานพาหนะ BMW ("BMW Configurator")
          และเครื่องมือประกอบยานพาหนะ MINI (MINI Configurator) (เว็บไซต์)
          ของท่าน
        </p>
        <p>
          2. เว็บไซต์นี้ช่วยให้ท่านสามารถประกอบยานพาหนะ BMW และ/หรือ MINI
          โดยการเลือกรุ่นพื้นฐานและเพิ่มตัวเลือกต่าง ๆ
          ที่อาจมีเพื่อปรับแต่งยานพาหนะนั้น ๆ
          และส่งข้อมูลส่วนตัวของคุณเพื่อนัดหมายสำหรับการทดลองขับ
          หรือเชื่อมไปยังเว็บไซต์ร้านค้า BMW และ/หรือ เว็บไซต์ร้านค้า MINI
          เพื่อจองยานพาหนะที่มีการประกอบที่เหมือนหรือคล้ายกัน (บริการ)
        </p>
        <p>
          3. ในการใช้บริการของเรา ท่านต้องมีอายุอย่างน้อย 20 (ยี่สิบ) ปี
          หรือมีความสามารถทางกฎหมายในการเข้าทำนิติกรรม
          และท่านต้องเป็นชาวไทยที่กำลังอยู่อาศัยในประเทศไทย
        </p>
        <p>
          4. บริษัท บีเอ็มดับเบิลยู (ประเทศไทย) จำกัด และ บริษัท บีเอ็มดับเบิลยู
          ลิสซิ่ง (ประเทศไทย) จำกัด&nbsp; (“
          <b>กลุ่ม BMW (ประเทศไทย)</b>” “<b>เรา</b>” “<b>พวกเรา</b>” และ “
          <b>ของเรา</b>”) เป็นผู้ให้บริการ และการที่ท่านใช้บริการนี้
          ท่านได้ตกลงกับข้อตกลงและเงื่อนไขเหล่านี้&nbsp;
        </p>
        <p>
          5. ข้อตกลงและเงื่อนไขเหล่านี้ บังคับใช้กับบริการนี้เท่านั้น
          บริการนี้ไม่รวมถึงบริการอื่น ๆ ที่ทางกลุ่ม BMW (ประเทศไทย) ให้บริการ
          ซึ่งบริการเหล่านั้นมีข้อตกลงและเงื่อนไขแยกต่างหากที่สามารถเข้าถึงได้ผ่านเว็บไซต์และแอปพลิเคชันที่เกี่ยวข้อง
        </p>
        <p>
          6. กลุ่ม BMW (ประเทศไทย) ไม่ใช่ผู้ขายยานพาหนะใด ๆ
          ที่แสดงให้เห็นระหว่างการใช้บริการ
          และไม่มีหน้าที่ในการขายหรือจัดส่งยานพาหนะใด ๆ ให้ท่าน
          หรือรับประกันเกี่ยวกับการขายที่ดำเนินการโดยตัวแทนจำหน่าย BMW และ/หรือ
          ตัวแทนจำหน่าย MINI ที่ได้รับอนุญาต
          (รวมถึงระยะเวลาที่ยานพาหนะนั้นวางจำหน่ายหรือสามารถจัดส่งให้แก่ท่าน)
          ทั้งนี้ เท่าที่กฎหมายอนุญาตไว้
        </p>
        <p>
          7. แม้ว่ากลุ่ม BMW (ประเทศไทย)
          จะใช้ความพยายามในการปรับปรุงให้ข้อมูลบนเว็บไซต์นั้นถูกต้องและเป็นปัจจุบันกลุ่ม
          BMW (ประเทศไทย) ไม่รับประกันหรือรับรองความสมบูรณ์ ความถูกต้อง
          หรือความมีอยู่ของข้อมูล
        </p>
        <p>
          8. ราคาที่แสดงบนเว็บไซต์เป็นราคาขายปลีกแนะนำจากผู้ผลิต (MSRP)
          ซึ่งรวมภาษีมูลค่าเพิ่มแล้ว ราคาที่ท่านชำระจริงสำหรับยานพาหนะของ BMW
          และ/หรือ MINI จะเป็นไปตามที่ท่านตกลงกับตัวแทนจำหน่าย
          และตามที่ระบุในสัญญาซื้อขายของตัวแทนจำหน่าย
        </p>
      </div>
    </div>
  )
}

export default ThaiTermsAndCons
