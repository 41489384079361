import React from "react"

const EnglishTermsAndCons = () => {
  return (
    <div style={{ maxWidth: "100%" }}>
      <h2>Terms and Conditions for Use of BMW Websites and BMW Application</h2>
      <div>
        <p>
          BMW Group Thailand
          <br />
          Version as of 29 April 2022
        </p>
        <p>&nbsp;</p>
        <p>
          These terms and conditions for use of BMW websites and BMW application
          ("<b>Terms of Use</b>") are considered as our agreement with our
          Platform users or customers ("<b>you</b>", "<b>your</b>" and "
          <b>yours</b>") for using our Platform or Services.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>1.&nbsp;Information about us, our Platform and Services</b>
        </p>
        <p>
          BMW websites, BMW application, and/or any other BMW online or
          electronic channels&nbsp;(collectively "<b>Platform</b>") are operated
          by BMW (Thailand) Co., Ltd. and BMW Leasing (Thailand) Co., Ltd. ("
          <b>BMW Group Thailand</b>", "<b>we</b>", "<b>us</b>" and "<b>our</b>
          "). We are registered in Thailand and have our registered office at
          87/2 44th, 50th and 51st Floor, CRC Tower, All Seasons Place, Wireless
          Road, Lumpini Sub-District, Pathumwan District, Bangkok 10330.
        </p>
        <p>
          Our Platform, including&nbsp;
          <a href="http://www.bmw.co.th/">www.bmw.co.th</a>,&nbsp;
          <a href="http://www.yourbmwleasingthailand.com/" target="_blank">
            www.yourbmwleasingthailand.com
          </a>
          ,&nbsp;
          <a href="http://www.mini.co.th/" target="_blank">
            www.mini.co.th
          </a>
          ,&nbsp;
          <a href="http://www.bmw-motorrad.co.th" target="_blank">
            www.bmw-motorrad.co.th
          </a>
          ("<b>BMW websites</b>") and/or My BMW application ("
          <b>BMW application</b>"), provides features, news, content,
          information, communication tools, online self-service portal,
          privileges, auxiliary and financial services in relation to our
          provision of vehicles and other related services (together or
          separately the "<b>Services</b>"), including but not limited to:
        </p>
        <ul>
          <li>Account registration</li>
          <li>Test drive appointment</li>
          <li>Vehicle customization</li>
          <li>Function on-demand</li>
          <li>ConnectedDrive services</li>
          <li>Event Booking application</li>
          <li>Online sales and digital stores</li>
          <li>Vehicle status management</li>
          <li>Remote services system</li>
          <li>BMW Configurator</li>
          <li>MINI Configurator</li>
        </ul>
        <p>
          By accessing or using our Platform, these Terms of Use will
          automatically apply to you and you are agreeing to be bound by them
          for the duration of your use of our Platform and Services. Please read
          these Terms of Use carefully before you start to use our Platform and
          Services, as these will apply to your ongoing use of both. Where you
          do not agree to these Terms of Use, please do not use or continue
          using our Platform and Services.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>2. Other applicable terms</b>
        </p>
        <p>
          These Terms of Use refer to the following additional terms, which also
          apply to your use of our Platform and Services:
        </p>
        <ul>
          <li>
            Our Legal Disclaimer –
            <a href="https://www.bmw.co.th/en/footer/metanavigation/legal-disclaimer-pool/legal-disclaimer.html">
              click here to view
            </a>
            ,<b> </b>which sets out a little more legal information about us and
            our Platform terms. Where there is a conflict between these Terms of
            Use and the Legal Disclaimer, these Terms of Use shall take
            precedence in relation to our Platform.
          </li>
          <li>
            Our Privacy Policy –
            <a href="https://www.bmw.co.th/en/footer/legal-information/privacy-policy.html">
              click here to view
            </a>
            , which sets out the terms on which we use or disclose any personal
            data we collect from you or that you provide to us. Please also see
            the section below on '<i>Personal Data</i>'.
          </li>
          <li>
            Our Cookie Policy –
            <a href="https://www.bmw.co.th/en/footer/footer-section/cookie-policy.html">
              click here to view
            </a>
            ,<b> </b>which sets out information about the cookies on our
            Platform.
          </li>
          <li>
            Our BMW Configurator terms and conditions -&nbsp;
            <a href="https://www.bmw.co.th/en/configurator.html">
              click here to view
            </a>
            , which sets out the terms and conditions applicable to the use of
            the "BMW Configurator" on our Platform.
          </li>
          <li>
            Our MINI Configurator terms and conditions -
            <a
              href="https://configure.mini.co.th/en_TH/model-ranges"
              target="_blank"
            >
              click here to view
            </a>
            , which sets out the terms and conditions applicable to the use of
            the "MINI Configurator on our Platform.
          </li>
        </ul>
        <p>
          Where there are other additional terms and conditions, guidelines
          and/or policies published pursuant to our Platform or Services, you
          agree to carefully read and comply with such terms and conditions,
          guidelines and/or policies, together with these Terms of Use, when
          using our Platform and Services.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>3.&nbsp;Changes to these Terms of Use</b>
        </p>
        <p>
          We can change any part of these Terms of Use, rules, policies and/or
          privileges in connection with our Services at any time by amending
          this page. We will always act reasonably when we do so. These Terms of
          Use, rules, policies and/or privileges in connection with our Services
          will only be changed for any of the following reasons:
        </p>
        <ul>
          <li>
            We reasonably believe that the change would make these Terms of Use
            easier to understand or fairer for you.
          </li>
          <li>
            We need to make the change as a result of changes in law, the
            decision of an Ombudsman or other regulatory requirement.
          </li>
          <li>
            We’re making the changes as a result of changes in industry codes or
            agreements, technology or the systems we use to run our business, or
            to reflect good practice but we will only do this if it as favorable
            or more favorable to you.
          </li>
          <li>To introduce changes to our Platform or new Services.</li>
        </ul>
        <p>
          Please check this page from time to time to take notice of any changes
          we may have made, as by continuing to use our Services, you agree to
          the changes and they are binding on you. You are free to stop using
          our Platform at any time if you do not want to accept any change we
          make to these Terms of Use.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <b>4.&nbsp;Changes to our Platform</b>
        </p>
        <p>
          We reserve the right to update our Platform from time to time, and may
          change the Services, functionality and content at any time. However,
          please note that any of the content on our Platform may be out of date
          at any given time, and we are under no obligation to update it.
        </p>
        <p>
          We do not guarantee that our Platform, Services, functionality or any
          content within, will be free from errors or omissions.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <b>5.&nbsp;Accessing our Platform</b>
        </p>
        <p>Our Platform is made available to you free of charge.</p>
        <p>
          We do not guarantee that our Platform, Services or any content within,
          will always be available or be uninterrupted. We may suspend,
          withdraw, discontinue or change all or any part of our Platform and
          Services without notice. We will not be liable to you if for any
          reason our Platform and/or Services is unavailable at any time or for
          any period.
        </p>
        <p>
          By accessing our Platform, you represent and warrant that you are at
          least 20 (twenty) years old, or that you have the legal capacity to
          enter into a juristic act.
        </p>
        <p>
          You are responsible for making all arrangements necessary for you to
          have access to our Platform. You are also responsible for ensuring
          that all persons who access our Platform through your internet
          connection or electronic services are aware of these Terms of Use and
          other applicable terms and conditions, and that they comply with them.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>6.&nbsp;Your account and password</b>
        </p>
        <p>
          You agree to register your account on our Platform in order to access
          certain Services, where you must keep all of your account-related
          details for accessing our Platform secret and take reasonable
          precautions to prevent them becoming known to another person.
        </p>
        <p>
          You can request for the change of your account credentials, such as
          your username, password, and/or PIN (Person Identification Number),
          subject to our terms and conditions. You will still be bound to any
          actions you have taken prior to such change.
        </p>
        <p>
          You must take reasonable steps to maintain the security of your
          device, for example:
        </p>
        <ul>
          <li>Make sure your device is locked when you’re not using it.</li>
          <li>Keep your device free of viruses, malware or spyware.</li>
          <li>
            Log out of our Platform when you’ve finished (you should never rely
            on us automatically logging you out).
          </li>
          <li>Avoid using unsecure or unencrypted Wi-Fi.</li>
          <li>
            Only use public Wi-Fi hotspots if you know they are trustworthy –
            fraudsters can set up malicious Wi-Fi networks that could intercept
            your data.
          </li>
        </ul>
        <p>You must not:</p>
        <ul>
          <li>
            Write down or record your security details for our Platform in a way
            which could be understood by someone else.
          </li>
          <li>Let anyone know or use your security details.</li>
          <li>
            Leave your device unattended after you’ve logged into our Platform.
          </li>
          <li>
            Let anyone else use your device if you are logged into our Platform.
          </li>
          <li>
            Set up a public computer as a computer you want us to recognize e.g.
            in an internet café.
          </li>
        </ul>
        <p>
          We reserve the right to disable any user identification code or
          password, or your access to our Platform or your account, whether
          chosen by you or allocated by us, at any time, if in our reasonable
          opinion you have failed to comply with any of the provisions of these
          Terms of Use or any applicable laws or regulations.
        </p>
        <p>
          If you know or suspect that anyone other than you knows your user
          identification code or password, or that your device is damaged, loss,
          or stolen, you must promptly notify us to cancel or suspend our
          Services, either by telephone on <a></a>
          <a href="tel:1397">1397</a>, or by email to
          <a href="mailto:callcenter@bmw.co.th" target="_blank">
            callcenter@bmw.co.th
          </a>
          &nbsp;We will not be held liable for such failure to notify.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>7.&nbsp;Use of our Platform</b>
        </p>
        <p>
          Any actions conducted using your account and password via our
          Platform, whether in relation to any of our Services, shall be deemed
          as fully effective and binding to you without the need of your
          signature in any of our documents, and you will be responsible for
          such actions. Nevertheless, we may request for additional documents if
          we deem appropriate.&nbsp;
        </p>
        <p>
          If applicable, you may not modify, suspend, or cancel any direct debit
          or payments after you have submit such request to us via our Platform.
          If such request has been made in advance, you may only modify,
          suspend, or cancel such request before we perform any actions
          regarding such request, or within our specified timeframe, subject to
          any other relevant terms and conditions.
        </p>
        <p>
          You agree that, by proceeding your request, we have duly provided you
          with our Services and such proceeding shall be binding to you.
        </p>
        <p>
          You represent and warrant that your documents and/or evidences
          regarding your data, or any of our proceedings with your data, and/or
          any evidences of our provision of Services which we proceeded at your
          request within this Terms of Use is accurate and binding to you.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>8. Intellectual property rights</b>
        </p>
        <p>
          All legal rights, titles, interest and any intellectual property
          rights in connection with the Platform and Services, including but not
          limited to names, logos and images on our Platform which identify any
          BMW Group Thailand company, or third parties and their products and
          services, belong solely with us and/or the relevant third parties. We
          hereby grants you the non-exclusive and revocable license to use the
          Platform and the Services for personal use only. Nothing contained in
          our Platform shall be deemed to confer on any person any license or
          right on the part of BMW AG or any third party with respect to any
          such name, logo or image. All such rights are reserved.
        </p>
        <p>
          The copyright in the material contained in our Platform belongs to a
          BMW Group Thailand company or its ultimate parent, BMW AG or their
          licensors. No person may copy, modify, transmit, distribute, display,
          reproduce, publish, license or create works from any part of this
          material or otherwise use it for any public or commercial use without
          the prior express written permission from us. You may only view or
          print individual pages for your own personal use.
        </p>
        <p>
          The Services and technology or processes described in our Platform may
          be subject to other intellectual property rights reserved by BMW Group
          Thailand companies or their licensors or any relevant third parties.
          No license is granted in respect of such intellectual property rights.
        </p>
        <p>
          You must not modify the paper or digital copies of any materials you
          have printed off or downloaded in any way, and you must not use any
          illustrations, photographs, video or audio sequences or any graphics
          separately from any accompanying text. Our status (and that of any
          identified contributors) as the authors of content on our Platform
          must always be acknowledged. You must not use any part of the content
          on our Platform for commercial purposes without obtaining a license to
          do so from us or our licensors.
        </p>
        <p>
          If you print off, copy or download any part of our Platform in breach
          of these Terms of Use, your right to use our Platform will cease
          immediately and you must, at our option, return or destroy any copies
          of the materials you have made.
        </p>
        <p>
          You grant us and our affiliates a royalty-free, non-exclusive, and
          worldwide right and license to use, reproduce, display, modify, and
          re-format any and all of your content provided by you strictly in
          accordance with your instructions and/or requirements, for the purpose
          of our operation or performance of the Platform and Services under
          these Terms of Use.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>9.&nbsp;No reliance on information</b>
        </p>
        <p>
          The content on our Platform, including any information regarding our
          financial services, information regarding any agreements, or any other
          information, is provided for general information only and the
          information stored on our Platform will be electronic copies of
          documents relating to your agreement and/or correspondence between us.
          It is not intended to amount to advice on which you should rely. You
          must obtain professional or specialist advice before taking, or
          refraining from, any action on the basis of the content on our
          Platform.
        </p>
        <p>
          Although we make reasonable efforts to update the information on our
          Platform, we make no representations, warranties or guarantees,
          whether express or implied, that the content on our Platform is
          accurate, complete or up-to-date. The information stored on our
          Platform will relate to your agreements and/or correspondence between
          us.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>10.&nbsp;Our liability to you </b>&nbsp;
        </p>
        <p>
          Nothing in these Terms of Use excludes or limits our liability for
          death or personal injury arising from our negligence, or our fraud or
          fraudulent misrepresentation, or any other liability that cannot be
          excluded or limited by Thai law.
        </p>
        <p>
          To the extent permitted by law, we exclude all conditions, warranties,
          representations or other terms which may apply to our Platform,
          Services or any content on it, whether express or implied.
        </p>
        <p>
          We and all of our officers, employees, directors, agents, contractors
          and assignors will not be liable to you or any user for any loss or
          damage, whether in contract, tort (including negligence), breach of
          statutory duty, or otherwise, even if foreseeable, arising directly or
          indirectly, under or in connection with:
        </p>
        <ul>
          <li>
            Use of, or inability to use, our Platform and/or the Services
            or&nbsp;
          </li>
          <li>
            Use of or reliance on any content displayed on our Platform or
            through the Services
          </li>
        </ul>
        <p>
          If you are a business user, please note that in particular, we will
          not be liable for:
        </p>
        <ul>
          <li>Loss of profits, sales, business, or revenue;</li>
          <li>Business interruption;</li>
          <li>Loss of anticipated savings;</li>
          <li>Loss of business opportunity, goodwill or reputation; or</li>
          <li>Any indirect or consequential loss or damage.</li>
        </ul>
        <p>
          If you are a consumer user, please note that we only provide our
          Platform and Services for domestic and private use. You agree not to
          use our Platform for any commercial or business purposes, and we have
          no liability to you for any loss of profit, loss of business, business
          interruption, or loss of business opportunity.
        </p>
        <p>
          We will not be liable to you or any other person for following events:
        </p>
        <ul>
          <li>
            You have insufficient funds in your account, or you have been barred
            from making any transaction with us;
          </li>
          <li>
            Any transaction or payment made by you which resulted in an
            overdraft of your account with your bank (if any);
          </li>
          <li>Actions resulted from compliance with legal proceedings;</li>
          <li>
            Faults or errors of the Services or the deduction of account for
            payment occurred before or during your use in which we have
            communicated to you;
          </li>
          <li>You have breached our terms and conditions; and</li>
          <li>Force majeure events.</li>
        </ul>
        <p>
          For the purpose of this Terms of Use, Force majeure means any
          circumstances beyond our control which obstructs our Services pursuant
          to this Terms of Use, and shall include any faults or malfunctions of
          our computer systems, power outages, any third party actions, or
          computer viruses which did not occur from our lack of standard
          security measures.
        </p>
        <p>
          We will not be liable for any loss or damage caused by a virus,
          distributed denial-of-service attack, or other technologically harmful
          material that may infect your computer equipment, computer programs,
          data or other proprietary material due to your use of our Platform or
          to your downloading of any content on it, or on any website linked to
          it.
        </p>
        <p>
          We assume no responsibility for the content of websites linked on our
          Platform. Such links should not be interpreted as endorsement by us of
          those linked websites. We will not be liable for any loss or damage
          that may arise from your use of them.
        </p>
        <p>
          Nothing in these Terms of Use is intended to establish, or shall be
          construed as establishing, joint and several liability and each BMW
          Group Thailand company shall not be liable for the acts and omissions
          of another BMW Group Thailand company.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>11.&nbsp;Acceptable use and content standards</b>
        </p>
        <p>
          If we make available, and should you make use of, a feature that
          allows you to upload content to our Platform or to make contact with
          other users of our Platform, you must comply with the acceptable use
          requirements and content standards set out below:&nbsp;<b></b>
        </p>
        <p>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;I.&nbsp;Prohibited uses</b>
        </p>
        <p>
          You may use our Platform and Services only for lawful purposes. You
          may not use our Platform:<b></b>
        </p>
        <ul>
          <li>
            In any way that breaches any applicable local, national or
            international law or regulation, and these Terms of Use.
          </li>
          <li>
            In any way that is unlawful or fraudulent, or has any unlawful or
            fraudulent purpose or effect.
          </li>
          <li>
            For the purpose of harming or attempting to harm minors in any way.
          </li>
          <li>
            To transmit, or procure the sending of, any unsolicited or
            unauthorized advertising or promotional material or any other form
            of similar solicitation (spam).
          </li>
          <li>
            To knowingly transmit any data, send or upload any material that
            contains viruses, Trojan horses, worms, time-bombs, keystroke
            loggers, spyware, adware, or any other harmful programs or similar
            computer code designed to adversely affect the operation of any
            computer software or hardware.
          </li>
        </ul>
        <p>You also agree:</p>
        <ul>
          <li>
            Not to reproduce, duplicate, copy or re-sell any part of our
            Platform in contravention of the provisions of these Terms of Use.
          </li>
          <li>
            Not to access without authority, interfere with, damage or disrupt:
            <br />
            o Any part of our Platform or Services;
            <br />
            o Any equipment or network on which our Platform is stored;
            <br />
            o Any software used in the provision of our Platform or Services; or
            <br />o Any equipment or network or software owned or used by any
            third party.&nbsp;
          </li>
        </ul>
        <p>
          <b>
            <br />
            &nbsp; &nbsp; II.&nbsp;Interactive services
          </b>
        </p>
        <p>
          We may from time to time provide interactive services on our Platform,
          including, without limitation:
        </p>
        <ul>
          <li>Chat rooms and chatbot</li>
          <li>Bulletin boards</li>
        </ul>
        <p>
          ("<b>Interactive Service</b>")
        </p>
        <p>
          Where we do provide any Interactive Service, we will provide clear
          information to you about the kind of service offered, if it is
          moderated and what form of moderation is used (including whether it is
          human or technical).
        </p>
        <p>
          We will do our best to assess any possible risks for users (and in
          particular, for children) from third parties when they use any
          Interactive Service provided on our Platform, and we will decide in
          each case whether it is appropriate to use moderation of the relevant
          service (including what kind of moderation to use) in the light of
          those risks. However, we are under no obligation to oversee, monitor
          or moderate any Interactive Service we provide on our Platform, and we
          expressly exclude our liability for any loss or damage arising from
          the use of any Interactive Service by a user in contravention of the
          content standards, whether our Platform or Services are moderated or
          not.
        </p>
        <p>
          Where we do moderate an Interactive Service, we will normally provide
          you with a means of contacting the moderator, should a concern or
          difficulty arise.&nbsp;
        </p>
        <p>
          <b>&nbsp;&nbsp;&nbsp;&nbsp;III.&nbsp;Content standards</b>
        </p>
        <p>
          These content standards apply to any and all material which you
          contribute to our Platform or communicated to use via our Platform ("
          <b>Contributions</b>"), and to any Interactive Services associated
          with it.
        </p>
        <p>
          You must comply with the spirit and the letter of the following
          standards. The standards apply to each part of any contribution as
          well as to its whole.
        </p>
        <p>Contributions must:</p>
        <ul>
          <li>
            Be accurate (where they state facts), to the extent that we will not
            be obligated to cross-examine such facts with other sources.
          </li>
          <li>Be genuinely held (where they state opinions).</li>
          <li>Be, to the extent applicable under the law, binding.</li>
          <li>
            Comply with applicable law in Thailand and in any country from which
            they are posted.
          </li>
        </ul>
        <p>Contributions must not:</p>
        <ul>
          <li>Contain any material which is defamatory of any person.</li>
          <li>
            Contain any material which is obscene, offensive, hateful or
            inflammatory.
          </li>
          <li>Promote sexually explicit material.</li>
          <li>Promote violence.</li>
          <li>
            Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </li>
          <li>
            Infringe any copyright, database right or trademark of any other
            person.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Be made in breach of any legal duty owed to a third party, such as a
            contractual duty or a duty of confidence.
          </li>
          <li>Promote any illegal activity.</li>
          <li>
            Be threatening, abuse or invade another’s privacy, or cause
            annoyance, inconvenience or needless anxiety.
          </li>
          <li>
            Be likely to harass, upset, embarrass, alarm or annoy any other
            person.
          </li>
          <li>
            Be used to impersonate any person, or to misrepresent your identity
            or affiliation with any person.
          </li>
          <li>
            Give the impression that they emanate from us, if this is not the
            case.
          </li>
          <li>
            Advocate, promote or assist any unlawful act such as (by way of
            example only) copyright infringement or computer misuse.
          </li>
        </ul>
        <p>
          <b>
            <br />
            12.&nbsp;Suspension and termination
          </b>
        </p>
        <p>
          We will determine, at our discretion, whether there has been a breach
          of the acceptable use and content standard through your use of our
          Platform or Services. When a breach has occurred, we may take such
          action, without any obligation to notify you, as we deem appropriate.
        </p>
        <p>
          Failure to comply with our acceptable use and content standards
          constitutes a material breach of these Terms of Use upon which you are
          permitted to use our Platform and Services, and may result in us
          taking all or any of the following actions:
        </p>
        <ul>
          <li>
            Immediate, temporary or permanent withdrawal of your right to use
            our Platform and Services.
          </li>
          <li>
            Immediate, temporary or permanent removal of any posting or material
            uploaded by you to our Platform.
          </li>
          <li>Issue a warning to you.</li>
          <li>
            Legal proceedings against you for reimbursement of all costs on an
            indemnity basis (including, but not limited to, reasonable
            administrative and legal costs) resulting from the breach.
          </li>
          <li>Further legal action against you.</li>
          <li>
            Disclosure of such information to law enforcement authorities as we
            reasonably feel is necessary.
          </li>
        </ul>
        <p>
          We exclude liability for actions taken in response to breaches of our
          acceptable use and content standards. The responses described above
          are not limited and we may take any other action we reasonably deem
          appropriate.
        </p>
        <p>
          You may terminate your use of our Services by providing written notice
          not less than fifteen (15) days prior to the effective date of such
          termination via our contact channels. If applicable, we retain the
          rights to invoice for any fees, costs, or expenses related to your use
          of our Services prior to the termination from your account.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>13.&nbsp;Changes to the acceptable use and content standards</b>
        </p>
        <p>
          We may revise our acceptable use and content standards at any time by
          amending this page. You are expected to check this page from time to
          time to take notice of any changes we make, as they are legally
          binding on you. Some of the provisions contained in our acceptable use
          and content standards herein may also be superseded by provisions or
          notices published elsewhere on our Platform.
        </p>
        <p>
          You warrant that any such contribution does comply with those
          standards, and you will be liable to us and indemnify us for any
          breach of that warranty. This means you will be responsible for any
          loss or damage we suffer as a result of your breach of warranty.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          <b>14.&nbsp;Viruses and errors</b>
        </p>
        <p>
          We do not guarantee that our Platform or Services will be secure or
          free from bugs, viruses or errors.
        </p>
        <p>
          You are responsible for configuring your information technology,
          computer programs and platform in order to access our Platform. You
          should use your own virus protection software.
        </p>
        <p>
          You must not misuse our Platform or Service by knowingly introducing
          viruses, Trojans, worms, logic bombs or other material which is
          malicious or technologically harmful. You must not attempt to gain
          unauthorized access to our Platform, the server on which our Platform
          is stored or any server, computer or database connected to our
          Platform. You must not attack our Platform via a denial-of-service
          attack or a distributed denial-of service attack. By breaching this
          provision, we may take legal action against you and your right to use
          our Platform will cease immediately.
        </p>
        <p>
          You must notify us immediately should you encounter any bugs, viruses,
          errors, or any abnormalities is using our Services. Such notification
          shall be sent to use via our contact channels, and shall at least
          include the information of the related or affected Services. You agree
          to cooperate with us in the investigation of such events. We will
          inform you of the investigation result via our selected mode of
          communication.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>15.&nbsp;Linking to our Platform</b>
        </p>
        <p>You must not establish a link to our Platform in any website.</p>
        <p>&nbsp;</p>
        <p>
          <b>16.&nbsp;Third party links and resources on our Platform</b>
        </p>
        <p>
          Where our Platform contains links to other sites and resources
          provided by third parties, these links are provided for your
          information only and does not imply that we endorse or have approved
          the linked site. We have no control over the contents of those sites
          or resources and we will not be responsible for any content or
          activity on those sites or resources. By using our Platform, you agree
          that your use of other sites and resources provided by third parties
          is entirely at your own risk.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>17. Confidentiality</b>
        </p>
        <p>
          You shall maintain in confidence all information and data relating to
          Platform, Services, products, business affairs, marketing and
          promotion plans or other operations and its associated companies which
          are disclosed to you by or on behalf of us, whether orally or in
          writing and whether before, on or after the date of these Terms of
          Use, or which are otherwise directly or indirectly acquired by you
          from us, or any of our affiliate companies, or created in the course
          of these Terms of Use. You shall further ensure that you only use such
          confidential information in order to use the Services, and shall not,
          without our prior written consent, disclose such information to any
          third party, nor use it for any other purpose.
        </p>
        <p>
          For the avoidance of doubt, confidential information does not include
          any information which:
        </p>
        <ul>
          <li>
            At the time of disclosure is generally available to and known by the
            public other than as a result of disclosure by the receiving party;
          </li>
          <li>
            Was within the receiving party’s possession prior to it being
            furnished to the receiving party, provided that the source of such
            information was not known by the receiving party to be bound by a
            confidentiality agreement or other contractual, legal or fiduciary
            obligation of confidentiality to the disclosing party or any other
            party with respect to such information; or
          </li>
          <li>
            Becomes available to the receiving party on a non-confidential basis
            from a source other than the disclosing party, provided that such
            source of information was not known by the receiving party to be
            bound by a confidentiality agreement or other contractual, legal or
            fiduciary obligation of confidentiality to the disclosing party with
            respect to such information.
          </li>
        </ul>
        <p>
          <b>&nbsp;</b>
        </p>
        <p>
          <b>
            <br />
            18.&nbsp;Personal data
          </b>
        </p>
        <p>
          You warrant and represent that you have read and understood our
          privacy policy that sets out the terms on which we use or disclose any
          personal data we collect from you or that you provide to us, copies of
          which are available at
        </p>
        <ul>
          <li>
            <a></a>
            <a href="https://www.bmw.co.th/en/footer/legal-information/privacy-policy.html">
              https://www.bmw.co.th/en/footer/legal-information/privacy-policy.html
            </a>
          </li>
          <li>
            <a
              href="https://www.mini.co.th/en_TH/home/footer/privacy-policy.html"
              target="_blank"
            >
              https://www.mini.co.th/en_TH/home/footer/privacy-policy.html
            </a>
          </li>
          <li>
            <a
              href="https://www.bmw-motorrad.co.th/en/public-pool/content-pool/privacy.html"
              target="_blank"
            >
              https://www.bmw-motorrad.co.th/en/public-pool/content-pool/privacy.html
            </a>
          </li>
        </ul>
        <p>
          You warrant and represent to us that any information (including
          personal data) provided to us is true, accurate, current, and
          complete, and will inform us if there are any changes to that
          information.
        </p>
        <p>
          Where you provide personal data of any other individuals or any third
          persons to us, you warrant and represent to us that:
        </p>
        <ul>
          <li>
            The individual to which the personal data relates has been informed
            of the collection, use, disclosure and/or cross-border transfer of
            the personal data as contemplated under these Terms of Use.
          </li>
          <li>
            You have obtained consent or relied upon another legal basis for the
            collection, use, disclosure and/or cross-border transfer of their
            personal data in compliance with applicable laws.
          </li>
          <li>
            You are authorized to disclose such personal data to us, so that we
            are entitled to collect, use, disclose and/or cross-border transfer
            the same for the purposes contemplated hereunder and the applicable
            privacy policies.<b></b>
          </li>
        </ul>
        <p>
          Subject to our privacy policy, you acknowledge and accept that we will
          record, retain, or process any correspondences, uses of our Platform,
          uses of our Service, and your data for the purpose of improving our
          service and/or ensuring that your data is up-to-date, and that such
          records may be used as an evidence against you in any legal
          proceedings, and that you waive any claims related to such records. To
          the extent permitted by the data protection law, we have no obligation
          to record or retain our correspondences or your data.
        </p>
        <p>
          You agree that we may send and/or disclose any non-personal data or
          anonymized data to Google, Google affiliates, and/or any person or
          juristic person in which we are a party to a contract or contractually
          related to, both in Thailand and abroad, for the purposes of
          collection, analyzation, and processing of the data.
        </p>
        <p>
          You can study the process of sending and/or disclosure of data to
          Google, along with Google's data analyzation at " How Google uses data
          when you use our partners’ sites or apps” at&nbsp;
          <a
            href="http://www.google.com/policies/privacy/partners/"
            target="_blank"
          >
            www.google.com/policies/privacy/partners/
          </a>
          or any URL designated by Google.
        </p>
        <p>
          Additionally, we may integrate contents from third parties into our
          Platform. An example of this is the incorporation of YouTube videos.
          We will also work with third parties such as analytic firms and
          business partners (for example, Salesforce, Google, Adobe, Facebook,
          Instagram, etc.) who may also set cookies or tracking technologies on
          our Platform. Such third parties may use cookies and other
          technologies (including Google Analytics, Adobe Analytics, etc.) to
          collect information about your online activities while you are using
          our Platform. This information may be used to measure usage of our
          Platform and websites, and to personalize advertising content on our
          website.
        </p>
        <p>
          These third parties are responsible for the cookies they set on our
          site, and we do not have access to or control over cookies or other
          features these third parties may use. The information practices of
          these third parties are not covered by our Privacy Policy, so please
          refer to the privacy policies of those third parties if you require
          further information.
        </p>
        <p>
          For more details on how Google Analytics and Adobe function, please
          refer to their privacy policies at 
          <a
            href="https://policies.google.com/privacy%E2%80%AFand%E2%80%AFhttps://www.adobe.com/privacy/policy.html"
            target="_blank"
          >
            https://policies.google.com/privacy and https://www.adobe.com/privacy/policy.html
          </a>
          .
        </p>
        <p>
          If you do not want to use the remarketing features, you can disable it
          by changing the appropriate settings at their privacy policies.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>19. Assignment</b>
        </p>
        <p>
          You shall not assign these Terms of Use, or any rights or duties under
          these Terms of Use, in whole or in part, without the prior written
          consent from us. We may assign these Terms of Use, or any rights or
          duties under these Terms of Use, in whole or in part, to others and
          you agree to consent and provide all necessary assistance and support,
          including all necessary documents processed upon our request
        </p>
        <p>.</p>
        <p>
          <b>20.&nbsp;Applicable law</b>
        </p>
        <p>
          These Terms of Use are made in Thai and English. In the event of a
          conflict between the Thai version and the English version, the English
          version of these Terms of Use shall come into force and prevail over
          the Thai version.
        </p>
        <p>
          These Terms of Use are governed by and construed in accordance with
          the laws of Thailand. Any dispute arising out of these Terms of Use
          shall be commenced and maintained exclusively in any court of
          competent subject-matter jurisdiction in Thailand.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>21.&nbsp;Notices</b>
        </p>
        <p>
          You acknowledge and agree that you can only specify one (1) phone
          number and one (1) email address to be used as a communication method
          with us or for the use of our Services.
        </p>
        <p>
          You agree to notify us immediately in writing via our contact channels
          of any changes to your contact address, telephone number, fax number,
          or email address.
        </p>
        <p>
          Any documents or notices we address to you or your contact person (if
          any) at the contact address and/or email address given to us will be
          deemed effective, and that you have acknowledged the content of such
          documents or notices.
        </p>
        <p>&nbsp;</p>
        <p>
          <b>22.&nbsp;Contact us</b>
        </p>
        <p>
          We hope you enjoy using our Services and will do our very best to
          provide you with Services that enhance your experience of interacting
          with us. If you want to contact us about these Terms of Use, you can
          do so using the following details:
        </p>
        <ul>
          <li>By sending us a secure message via our Platform</li>
          <li>
            By email:
            <a href="mailto:callcenter@bmw.co.th" target="_blank">
              callcenter@bmw.co.th
            </a>
          </li>
          <li>
            By phone: <a href="tel:1397">1397</a>
          </li>
          <li>
            By writing to us at: BMW (Thailand) Co., Ltd&nbsp;87/2 44
            <sup>th</sup> and 50<sup>th</sup>-51<sup>st</sup> Floor, CRC Tower,
            All Seasons Place, Wireless Road, Lumpini Sub-District, Pathumwan
            District, Bangkok 10330.&nbsp;
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <b>BMW Configurator / MINI Configurator</b>
        </p>
        <p>
          1. These terms and conditions apply to your use of the "BMW
          Configurator" and "MINI Configurator" website (Website).
        </p>
        <p>
          2. The Website allows you to configure &nbsp;BMW and/or MINI vehicles
          by selecting a base model and applying various options which may be
          available to customize the relevant vehicle, submit your personal
          information to arrange a test drive, or link to the BMW Shop and/or
          MINI Shop website to reserve a vehicle with the same or a similar
          configuration (the Service).
        </p>
        <p>
          3. To be eligible to use the Service, you must be at least 20 (twenty)
          years old, or that you have the legal capacity to enter into a
          juristic act, and must be a Thai resident residing in Thailand.
        </p>
        <p>
          4. BMW (Thailand) Co., Ltd. and BMW Leasing (Thailand) Co., Ltd. ("
          <b>BMW Group Thailand</b>", "<b>we</b>", "<b>us</b>" and "<b>our</b>")
          offers the Service, and by using the Service, you agree to these terms
          and conditions.
        </p>
        <p>
          5. These terms and conditions govern the Service only. The Service
          does not include other services provided by BMW Group Thailand, which
          are governed by separate terms and conditions available at the
          relevant websites or applications.
        </p>
        <p>
          6. BMW Group Thailand is not the seller of any vehicles shown using
          the Service, and, to the extent permitted by law, is not responsible
          for the sale or delivery of any vehicle to you or any representations
          about the sale made by an authorized BMW Dealer and/or MINI Dealer
          (including with regard to the time that a vehicle may be available for
          purchase or delivery to you).
        </p>
        <p>
          7. While BMW Group Thailand strives to ensure that the information set
          out in the Website is correct and up-to-date, BMW Group Thailand does
          not represent or warrant the completeness, accuracy or availability of
          the information.
        </p>
        <p>
          8. All prices displayed on the website are Manufacturer Suggested
          Retail Price (MSRP) which already include VAT. The actual drive away
          price you pay for any BMW and/or MINI vehicles will be agreed by you
          with the selling Dealer and set out in the Dealer Sales Contract.
        </p>
      </div>
    </div>
  )
}

export default EnglishTermsAndCons
